/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.result_card___-21wQ1ViyB5HjteQKkqBFIj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f2f2f0;
  border: 1px solid #e2e2e2; }
  .result_card___-21wQ1ViyB5HjteQKkqBFIj.small___-2xWRldVOlE3Z1yHh7fD1UM .result_card__content___-1T6uaRI56CzuTSydSf3Ike {
    min-height: 230px;
    padding: 20px;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
  .result_card___-21wQ1ViyB5HjteQKkqBFIj.small___-2xWRldVOlE3Z1yHh7fD1UM .result_card__content___-1T6uaRI56CzuTSydSf3Ike h3 {
    font-size: 1.5rem;
    line-height: 1.6875rem;
    white-space: pre-wrap;
    display: block; }
  .result_card___-21wQ1ViyB5HjteQKkqBFIj.small___-2xWRldVOlE3Z1yHh7fD1UM .result_card__location___-3sbhTgtZJgy5JjRWQL6Ua1 {
    font-size: 0.875rem;
    line-height: 0.875rem; }
  @media (min-width: 1026px) {
    .result_card___-21wQ1ViyB5HjteQKkqBFIj.small___-2xWRldVOlE3Z1yHh7fD1UM .result_card__image___-260gAGQA2KhcexDh9sszlJ {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 35%;
              flex: 1 1 35%; } }
  .result_card___-21wQ1ViyB5HjteQKkqBFIj.small___-2xWRldVOlE3Z1yHh7fD1UM .result_card__bottom___-3sGy1yj-HavJ2wWXEGQkGK {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .result_card___-21wQ1ViyB5HjteQKkqBFIj:hover .result_card__cta___-39zKoqZwffIpJoMf1NBGFZ a[class*="cta"],
  .result_card___-21wQ1ViyB5HjteQKkqBFIj:hover .result_card__cta___-39zKoqZwffIpJoMf1NBGFZ span[class*="cta"] {
    background: #f6891f; }

.result_card__cta___-39zKoqZwffIpJoMf1NBGFZ a[class*="cta"],
.result_card__cta___-39zKoqZwffIpJoMf1NBGFZ span[class*="cta"] {
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out; }

.result_card__title___-3SSgYVY_B_sC_MGgmuFMG5 {
  margin-bottom: 10px; }

.result_card__subtitle___-2_yeJVfeg6VvgnXVj00Z6N {
  font-size: 1rem;
  line-height: 1rem;
  margin: 10px 0px 0px; }
  @media (min-width: 600px) {
    .result_card__subtitle___-2_yeJVfeg6VvgnXVj00Z6N {
      margin: 20px 0px 0px; } }

.result_card__image___-260gAGQA2KhcexDh9sszlJ {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%; }
  @media (min-width: 768px) {
    .result_card__image___-260gAGQA2KhcexDh9sszlJ {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 35%;
              flex: 0 0 35%; } }
  @media (min-width: 1026px) {
    .result_card__image___-260gAGQA2KhcexDh9sszlJ {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; } }

.result_card__content___-1T6uaRI56CzuTSydSf3Ike {
  padding: 15px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out; }
  .result_card__content___-1T6uaRI56CzuTSydSf3Ike span {
    font-size: 0.9375rem;
    line-height: 1.0625rem; }
  .result_card__content___-1T6uaRI56CzuTSydSf3Ike h3 {
    font-size: 1.25rem;
    line-height: 1.5rem; }
  @media (min-width: 600px) {
    .result_card__content___-1T6uaRI56CzuTSydSf3Ike {
      padding: 35px; }
      .result_card__content___-1T6uaRI56CzuTSydSf3Ike span {
        font-size: 1rem; } }
    @media (min-width: 600px) and (min-width: 1026px) {
      .result_card__content___-1T6uaRI56CzuTSydSf3Ike span {
        font-size: 1rem; } }
  @media (min-width: 600px) {
      .result_card__content___-1T6uaRI56CzuTSydSf3Ike h3 {
        font-size: 1.6rem;
        line-height: calc(1.6rem + 0.4rem); } }
    @media (min-width: 600px) and (min-width: 1026px) {
      .result_card__content___-1T6uaRI56CzuTSydSf3Ike h3 {
        font-size: 2.2rem;
        line-height: calc(2.2rem + 0.4rem); } }
  @media (min-width: 768px) {
    .result_card__content___-1T6uaRI56CzuTSydSf3Ike {
      min-height: 250px; } }
  @media (min-width: 1026px) {
    .result_card__content___-1T6uaRI56CzuTSydSf3Ike {
      min-height: 400px;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; } }

.result_card__location___-3sbhTgtZJgy5JjRWQL6Ua1 {
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  color: #7f7f7f;
  margin-bottom: 10px;
  display: block; }

.result_card__blurb___-2Iy-rcXl7guo-4falRQu77 {
  font-family: "Akkurat", sans-serif;
  font-weight: 300;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: none; }
  @media (min-width: 600px) {
    .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 {
      display: block; } }
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h1,
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h2,
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h3,
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h4,
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h5,
  .result_card__blurb___-2Iy-rcXl7guo-4falRQu77 h6 {
    font-size: 1.5rem;
    line-height: 1.6875rem; }

.result_card__details___-iMQaOaH8iFhkCJGf5G9LS {
  margin-top: 30px;
  display: none; }
  .result_card__details___-iMQaOaH8iFhkCJGf5G9LS > span {
    font-family: "Akkurat", sans-serif;
    font-weight: 300;
    text-transform: capitalize; }
    .result_card__details___-iMQaOaH8iFhkCJGf5G9LS > span:first-child {
      margin-right: 20px; }
  @media (min-width: 600px) {
    .result_card__details___-iMQaOaH8iFhkCJGf5G9LS {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

@media (min-width: 600px) {
  .result_card__bottom___-3sGy1yj-HavJ2wWXEGQkGK {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .result_card__bottom___-3sGy1yj-HavJ2wWXEGQkGK > p {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .result_card__bottom___-3sGy1yj-HavJ2wWXEGQkGK > div {
      -webkit-box-flex: 0;
          -ms-flex: none;
              flex: none;
      white-space: pre;
      margin-top: 0px; } }

.result_card__bottom__price___-1SiBKC7w6N-8FV60yC4gri {
  margin-top: 20px;
  margin-bottom: 10px; }
  .result_card__bottom__price___-1SiBKC7w6N-8FV60yC4gri > p:nth-of-type(2) {
    font-weight: bold; }
