/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.group_travel___-3hCrkFMobNkdWuwjq3DDf {
  display: block; }

.group_travel__container___-2xTftJhiv2SHqjILBjlHVb {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .group_travel__container___-2xTftJhiv2SHqjILBjlHVb {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .group_travel__container___-2xTftJhiv2SHqjILBjlHVb {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .group_travel__container___-2xTftJhiv2SHqjILBjlHVb {
      padding-left: 120px;
      padding-right: 120px; } }

.group_travel__form_container___-29ywPotRwc8oQOzg26Wi5f {
  border-top: 1px solid black;
  margin-top: 50px;
  padding: 50px 0px 0px; }
  @media (min-width: 1180px) {
    .group_travel__form_container___-29ywPotRwc8oQOzg26Wi5f {
      padding: 50px 150px 0px; } }
