/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.print_page_form___-o2Fi_ELEP-MlkQn9rwsGx {
  display: block; }

.print_page_form__modal___-2hzVhoMujMflxnKFTOtXXd {
  display: block; }

.print_page_form__modal__container___-qqRQsrGAVkW2V1VLT1rBx {
  background: #ffffff;
  padding: 50px 30px 10px; }
  @media (min-width: 1026px) {
    .print_page_form__modal__container___-qqRQsrGAVkW2V1VLT1rBx {
      padding: 70px 50px 0px; } }
