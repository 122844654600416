/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.add_ons___-2UirWWL4DtqvZAr1N8KRD9 {
  display: block; }

.add_ons__item___-3iqsIvyvO5nKSBlBUkby1A {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }

.add_ons__item__details___-3xzCZRmJ8o9RM19pNcV1NC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
  margin-right: 40px; }

.add_ons__item__details__title___-fcW3QXApAMTqljySEuX00 {
  text-decoration: underline;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  margin-top: 10px; }

.add_ons__item__pricing___-2BifbfMZP1rXfvi5kkXiYr {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  text-align: right; }
  .add_ons__item__pricing___-2BifbfMZP1rXfvi5kkXiYr h3,
  .add_ons__item__pricing___-2BifbfMZP1rXfvi5kkXiYr p {
    text-align: right; }
  .add_ons__item__pricing___-2BifbfMZP1rXfvi5kkXiYr h3 {
    font-size: 1.875rem;
    line-height: 2.125rem; }
  .add_ons__item__pricing___-2BifbfMZP1rXfvi5kkXiYr p {
    font-size: 0.8125rem;
    line-height: 0.9375rem; }

.add_ons__item__cta___-3YZDSFCzA-UJ27p88NRffZ {
  margin-top: 20px;
  white-space: pre; }

p.add_ons__item__selected_text___-3HqBPs8CPUz9sSN0z-sj85 {
  margin-top: 20px; }
  p.add_ons__item__selected_text___-3HqBPs8CPUz9sSN0z-sj85 svg path {
    fill: green; }
