/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.cookie_popup___-2vSkF4grTCMcLsbMenkhAP {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000000;
  z-index: 10;
  padding: 10px;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out; }
  .cookie_popup___-2vSkF4grTCMcLsbMenkhAP.cookie_popup__hidden___-K69Tz7XhmB8nJ4DBYSLI3 {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  .cookie_popup___-2vSkF4grTCMcLsbMenkhAP button[class*="cta"] {
    white-space: pre;
    padding: 8px 10px; }

.cookie_popup__container___-1F1tiqFqk4eOIuixD0t9oE {
  margin: 0px auto;
  text-align: center; }
  @media (min-width: 600px) {
    .cookie_popup__container___-1F1tiqFqk4eOIuixD0t9oE {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 0px 35px; } }

.cookie_popup__blurb___-3rIRj0NGpXYHDzNMF3wFQB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .cookie_popup__blurb___-3rIRj0NGpXYHDzNMF3wFQB p {
    color: #ffffff;
    text-align: center;
    padding: 0px 25px 15px 0px;
    font-size: 0.8rem;
    line-height: 1.4rem; }
  .cookie_popup__blurb___-3rIRj0NGpXYHDzNMF3wFQB a,
  .cookie_popup__blurb___-3rIRj0NGpXYHDzNMF3wFQB a:visited {
    color: #ffffff;
    font-size: 0.8rem;
    line-height: 1.4rem;
    text-decoration: underline; }
  @media (min-width: 600px) {
    .cookie_popup__blurb___-3rIRj0NGpXYHDzNMF3wFQB p {
      text-align: left;
      padding: 0px 25px 0px 0px; } }

.cookie_popup__close___-1_jR9uLfLAWLAsSaYWiW-u {
  position: absolute;
  right: 15px;
  top: 10px; }
  .cookie_popup__close___-1_jR9uLfLAWLAsSaYWiW-u button[class*="hamburger"] {
    display: block;
    width: 25px;
    height: 25px; }
    .cookie_popup__close___-1_jR9uLfLAWLAsSaYWiW-u button[class*="hamburger"] div[class*="line"] {
      height: 2px;
      width: 75%;
      left: 6px;
      background-color: #ffffff; }
  @media (min-width: 600px) {
    .cookie_popup__close___-1_jR9uLfLAWLAsSaYWiW-u {
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); } }
