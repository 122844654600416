/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.intro___-L15EgnLhA-0Q_tuAneLWh {
  display: block;
  text-align: center;
  max-width: 800px;
  margin: 0px auto 60px; }

.intro__title___-GgRO26AI7anujDQe_R7Zn {
  font-family: "Baskerville", serif; }

.intro__subtitle___-1Xd-BUwEgxhL1lkaKmMqCd {
  font-family: "Baskerville", serif;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0px; }
  .intro__subtitle___-1Xd-BUwEgxhL1lkaKmMqCd:not(:last-child) {
    margin-bottom: 40px; }
  @media (min-width: 768px) {
    .intro__subtitle___-1Xd-BUwEgxhL1lkaKmMqCd {
      font-size: 1.875rem;
      line-height: 2.5rem; } }

.intro__blurb___-21h2y3B0wmF_53aeIe7EaP ul {
  text-align: justify;
  padding-left: 30px;
  margin: 10px auto;
  display: inline-block; }
  .intro__blurb___-21h2y3B0wmF_53aeIe7EaP ul li {
    list-style: disc; }

.intro__cta___-1pKD3dve9HS_GoerksdGkj {
  margin-top: 40px; }
