/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.image_and_text___-3ynQ5KqTadxEheYKOIlm0q {
  display: block;
  text-align: center;
  margin: 30px 0px; }
  @media (min-width: 1026px) {
    .image_and_text___-3ynQ5KqTadxEheYKOIlm0q {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .image_and_text___-3ynQ5KqTadxEheYKOIlm0q.right___-3kKwP0_TnNbC7hRifPAUyb {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse; }
        .image_and_text___-3ynQ5KqTadxEheYKOIlm0q.right___-3kKwP0_TnNbC7hRifPAUyb > div:last-child {
          margin-right: 30px; }
      .image_and_text___-3ynQ5KqTadxEheYKOIlm0q:not(.right___-3kKwP0_TnNbC7hRifPAUyb) > div:first-child {
        margin-right: 30px; } }

.image_and_text__image___-2FZL0sHvCHX12J7mTtPT-1 {
  margin-bottom: 30px;
  height: 50vw; }
  @media (min-width: 1026px) {
    .image_and_text__image___-2FZL0sHvCHX12J7mTtPT-1 {
      min-height: 490px;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%;
      height: auto;
      margin-bottom: 0px; } }

.image_and_text__content___-nzBNQQqPOHrIpuqALEmDn {
  padding: 40px 30px;
  background: #f2f2f0;
  text-align: left; }
  .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn p,
  .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn span,
  .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn a {
    font-size: 1.0625rem;
    line-height: 1.625rem; }
  .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn ul {
    padding-left: 20px; }
    .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn ul li {
      list-style: disc; }
  @media (min-width: 768px) {
    .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn {
      padding: 60px 50px; } }
  @media (min-width: 1026px) {
    .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; }
      .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .image_and_text__content___-nzBNQQqPOHrIpuqALEmDn ul li {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 50%;
                  flex: 1 1 50%; } }

.image_and_text__title___-2EzIzIvO9CcmS3rJPL3oAS {
  font-family: "Baskerville", serif;
  font-size: 2.5rem;
  line-height: 2.8125rem; }

.image_and_text__cta___-2OfnwyjnUXVCk2pAsbBH1J {
  margin-top: 20px; }

.image_and_text__images___-zirFFmOiRTnLZ881Lxylp {
  margin-bottom: 30px;
  height: 50vw; }
  @media (min-width: 1026px) {
    .image_and_text__images___-zirFFmOiRTnLZ881Lxylp {
      min-height: 490px;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%;
      height: auto;
      margin-bottom: 0px; } }
  .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-arrow"] {
    display: block;
    background: white;
    border: 1px solid #7f7f7f;
    padding: 20px;
    border-radius: 100%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0.7; }
    .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-arrow"]:before {
      width: 9px;
      height: 9px;
      border-color: #7f7f7f; }
  .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-next"] {
    right: 10px; }
    .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-next"]:before {
      right: 16px; }
  .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-prev"] {
    left: 10px; }
    .image_and_text__images___-zirFFmOiRTnLZ881Lxylp button[class~="slick-prev"]:before {
      left: 16px; }
