/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.more_places___-1kZNq_aB8FslqmivUW9QKv {
  text-align: center;
  background: #e9ece4; }

.more_places__container___-3FOwDpn3tFZjmab_15F_U9 {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 480px) {
    .more_places__container___-3FOwDpn3tFZjmab_15F_U9 {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .more_places__container___-3FOwDpn3tFZjmab_15F_U9 {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .more_places__container___-3FOwDpn3tFZjmab_15F_U9 {
      padding-left: 120px;
      padding-right: 120px; } }

.more_places__list___-13GaK0uKX5tH6lYb_M9rfi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1026px) {
    .more_places__list___-13GaK0uKX5tH6lYb_M9rfi {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

.more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt:hover {
    opacity: 0.7; }
  .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt:nth-child(odd):not(:last-child) {
    margin-right: 4%; }
  .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt a {
    display: block; }
  @media (min-width: 1026px) {
    .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 22%;
              flex: 1 1 22%; }
      .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt:nth-child(odd) {
        margin-right: 0; }
      .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt:not(:last-child) {
        margin-right: 2.7%; } }
  .more_places__list__item___-pSVdbbHTtXq8jbHpM0yKt h3 {
    margin-top: 15px;
    font-family: "Akkurat", sans-serif;
    font-size: 1.0625rem;
    line-height: 1.0625rem; }

.more_places__list__item__image___-2LMLmT2MJ0QZhzdLMGNPSV {
  height: 30vw; }
  @media (min-width: 1026px) {
    .more_places__list__item__image___-2LMLmT2MJ0QZhzdLMGNPSV {
      max-height: 380px; } }

.more_places__carousel___-rrfPUslnyV84cNHiWn1q8 {
  height: 420px; }
  .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 div[class="slick-track"] {
    margin: 0px auto; }
  .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 div[class~="slick-slide"] {
    padding: 20px 20px 60px; }
    .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 div[class~="slick-slide"] span {
      font-size: 1rem;
      line-height: 1.125rem; }
  .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 button[class~="slick-arrow"] {
    background: #ffffff;
    border: 1px solid #7f7f7f;
    border-radius: 100%;
    height: 45px;
    width: 45px; }
    .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 button[class~="slick-arrow"]:before {
      border-color: #7f7f7f; }
  .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 button[class~="slick-next"]:before {
    border-color: #7f7f7f;
    right: 50%;
    -webkit-transform: translate(25%, 0%) rotate(135deg);
            transform: translate(25%, 0%) rotate(135deg);
    height: 12px;
    width: 12px; }
  .more_places__carousel___-rrfPUslnyV84cNHiWn1q8 button[class~="slick-prev"]:before {
    border-color: #7f7f7f;
    left: 50%;
    -webkit-transform: translate(-25%, 0%) rotate(-45deg);
            transform: translate(-25%, 0%) rotate(-45deg);
    height: 12px;
    width: 12px; }
