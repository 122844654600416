/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.town_card___-12hHKpmwmyxXX9XAwWsaQy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f2f2f0;
  border: 1px solid #e2e2e2;
  max-width: 650px;
  margin: 0px auto;
  position: relative;
  min-height: 120px; }
  @media (min-width: 480px) {
    .town_card___-12hHKpmwmyxXX9XAwWsaQy {
      min-height: 180px; } }

.town_card__image___-1_EhNaVJ8PbZo4zsMMLdBB {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
          flex: 0 0 120px;
  height: 120px;
  width: 120px;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }
  @media (min-width: 480px) {
    .town_card__image___-1_EhNaVJ8PbZo4zsMMLdBB {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 180px;
              flex: 0 0 180px;
      height: 180px;
      width: 180px; } }

.town_card__content___-12P-1wTIKqakTuKuuEI5G4 {
  padding: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  margin-left: 120px; }
  @media (min-width: 480px) {
    .town_card__content___-12P-1wTIKqakTuKuuEI5G4 {
      margin-left: 180px; } }
  .town_card__content___-12P-1wTIKqakTuKuuEI5G4 h3 {
    font-size: 1.5rem;
    line-height: 1.6875rem;
    white-space: pre-wrap;
    display: block;
    margin-bottom: 5px; }
  @media (min-width: 1026px) {
    .town_card__content___-12P-1wTIKqakTuKuuEI5G4 {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; } }

.town_card__location___-KoZNs-YPFA_WJQID6CMZn {
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  color: #7f7f7f;
  margin-bottom: 10px;
  display: block;
  font-size: 0.875rem;
  line-height: 0.875rem; }

.town_card__blurb___-2XGVBqPQBjcbIWZJlu8QCU {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .town_card__blurb___-2XGVBqPQBjcbIWZJlu8QCU p {
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: "Akkurat", sans-serif;
    font-weight: 300; }
  .town_card__blurb___-2XGVBqPQBjcbIWZJlu8QCU h4 {
    margin-bottom: 0px;
    font-size: 1.125rem;
    line-height: 1.125rem; }

.town_card__details___-1Z6p6s97rVOk45jYyj6wmr {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .town_card__details___-1Z6p6s97rVOk45jYyj6wmr > span {
    font-family: "Akkurat", sans-serif;
    font-weight: 300; }
    .town_card__details___-1Z6p6s97rVOk45jYyj6wmr > span:first-child {
      margin-right: 20px; }

.town_card__bottom___-34hOuRGqKtn_Pc4SglbSuh {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }
  .town_card__bottom___-34hOuRGqKtn_Pc4SglbSuh > p {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .town_card__bottom___-34hOuRGqKtn_Pc4SglbSuh > div {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    white-space: pre; }

html[class*="accessible"] .town_card__image___-1_EhNaVJ8PbZo4zsMMLdBB {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0; }
