/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.grid___-3RkCHA02rwazamqG6booUC {
  overflow: hidden;
  margin: 60px 0px; }
  @media (min-width: 768px) {
    .grid___-3RkCHA02rwazamqG6booUC {
      margin: 60px 40px; } }
  @media (min-width: 768px) {
    .grid___-3RkCHA02rwazamqG6booUC.short___-NZgn0ytwLsgA9wcd0Jl6o {
      margin: 60px 0px; } }

.grid__title_wrapper___-3Y68MFCnticLBbcrw68CVe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.grid__decor___-1PJekUSsMgojD577yGPba2 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  height: 1px;
  background-color: #e2e2e2; }

.grid__title___-2eSQxOF4UY2Q0FqXRK3tc3 {
  width: 100%; }
  .grid__title___-2eSQxOF4UY2Q0FqXRK3tc3 h2 {
    margin: 0px;
    padding: 0px 20px;
    text-align: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .grid__title___-2eSQxOF4UY2Q0FqXRK3tc3.short___-NZgn0ytwLsgA9wcd0Jl6o h2 {
    text-transform: uppercase;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    color: #000000;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    padding: 0px; }

.grid__inner___-27yDYdDVDG949J67HB3QxZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.grid__item__title___-2Oufyf1g9M8CdyNWX_D95a {
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  margin: 5px 0px 15px; }

.grid__item___-3vqUUxXSZwBCxWlw3ooZPQ {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ:not(.short___-NZgn0ytwLsgA9wcd0Jl6o) {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 46%;
              flex: 0 0 46%; }
      .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ:not(.short___-NZgn0ytwLsgA9wcd0Jl6o):nth-child(2n) {
        margin-left: 8%; } }
  @media (min-width: 1026px) {
    .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ:not(.short___-NZgn0ytwLsgA9wcd0Jl6o) {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%; }
      .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ:not(.short___-NZgn0ytwLsgA9wcd0Jl6o):nth-child(2n) {
        margin-left: 0; }
      .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ:not(.short___-NZgn0ytwLsgA9wcd0Jl6o):not(:nth-child(4n)) {
        margin-right: 6.5%; } }
  .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 46%;
            flex: 0 0 46%; }
    .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o:nth-child(2n) {
      margin-left: 8%; }
    @media (min-width: 768px) {
      .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 31.6669%;
                flex: 0 0 31.6669%; }
        .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o:nth-child(2n) {
          margin-left: 0; }
        .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o:not(:nth-child(3n)) {
          margin-right: 2.5%; } }
    .grid__item___-3vqUUxXSZwBCxWlw3ooZPQ.short___-NZgn0ytwLsgA9wcd0Jl6o h3 {
      font-family: "Akkurat", sans-serif;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 0.875rem;
      max-width: 80%; }
