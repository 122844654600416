/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.who_we_are___-A69O5dlga7PT9BVBKhrAY {
  display: block; }

.who_we_are__container___-6j7W9TvRqdi6TYVFabiY3 {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .who_we_are__container___-6j7W9TvRqdi6TYVFabiY3 {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .who_we_are__container___-6j7W9TvRqdi6TYVFabiY3 {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .who_we_are__container___-6j7W9TvRqdi6TYVFabiY3 {
      padding-left: 120px;
      padding-right: 120px; } }

.who_we_are__intro___-2HnmFVcq1pMTTnpk3luspH {
  margin: 80px auto 0px; }

.who_we_are__image_column___-3JTSVcYeB-JnUj7ZBPdaxw {
  margin: 50px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 768px) {
    .who_we_are__image_column___-3JTSVcYeB-JnUj7ZBPdaxw {
      margin: 80px 0px 40px; } }

.who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  height: 40vw;
  max-height: 500px; }
  .who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh:first-child {
    margin-right: 15px;
    margin-left: -30px; }
  .who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh:last-child {
    margin-right: -30px; }
  @media (min-width: 768px) {
    .who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh {
      height: 40vw; }
      .who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh:first-child {
        margin-right: 30px;
        margin-left: 0px; }
      .who_we_are__image_wrapper___-1HDG1C8zFVuvJ_N_g12tDh:last-child {
        margin-right: 0px; } }

.who_we_are__bottom_image___-Y3MQkIIl9sCwpxF0-kRS3 {
  height: 40vw;
  max-height: 500px;
  margin: 50px -30px; }
  @media (min-width: 768px) {
    .who_we_are__bottom_image___-Y3MQkIIl9sCwpxF0-kRS3 {
      margin: 40px 0px 80px; } }
