/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.hotels___-1EfhFA3ZQ2zNk_GrLhsHXz,
.villas___-3SbF4ShnuUQuVvGNVfI93q,
.collections___-18eSu2T-MHUkNCQkOXOTAX {
  display: block; }

.hotels__container___-pjLkaKkHNsa8FCxpLZqeb,
.villas__container___-vv3bXqm3YrynIPFb-Mzqm,
.collections__container___-7ZojbNOXschXEMrJOd4V {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 30px; }
  @media (min-width: 480px) {
    .hotels__container___-pjLkaKkHNsa8FCxpLZqeb,
    .villas__container___-vv3bXqm3YrynIPFb-Mzqm,
    .collections__container___-7ZojbNOXschXEMrJOd4V {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .hotels__container___-pjLkaKkHNsa8FCxpLZqeb,
    .villas__container___-vv3bXqm3YrynIPFb-Mzqm,
    .collections__container___-7ZojbNOXschXEMrJOd4V {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .hotels__container___-pjLkaKkHNsa8FCxpLZqeb,
    .villas__container___-vv3bXqm3YrynIPFb-Mzqm,
    .collections__container___-7ZojbNOXschXEMrJOd4V {
      padding-left: 120px;
      padding-right: 120px; } }
