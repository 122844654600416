@charset "UTF-8";
/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.las_catalinas___-BN8bLh0jZ8JBx_0gNTT6c {
  display: block; }
  .las_catalinas___-BN8bLh0jZ8JBx_0gNTT6c h1 {
    font-size: 1.9rem;
    line-height: calc(1.9rem + 0.4rem); }
    @media (min-width: 1026px) {
      .las_catalinas___-BN8bLh0jZ8JBx_0gNTT6c h1 {
        font-size: 2.5rem;
        line-height: calc(2.5rem + 0.4rem); } }

.las_catalinas__container___-sZ-TN4NsMLZSKFYHGa3jT {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .las_catalinas__container___-sZ-TN4NsMLZSKFYHGa3jT {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .las_catalinas__container___-sZ-TN4NsMLZSKFYHGa3jT {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .las_catalinas__container___-sZ-TN4NsMLZSKFYHGa3jT {
      padding-left: 120px;
      padding-right: 120px; } }
  .las_catalinas__container___-sZ-TN4NsMLZSKFYHGa3jT div[class*="intro__cta"] {
    margin-bottom: 20px; }

.las_catalinas__image_grid___-1V2xuMyP3KfA8RpReWTmz_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0px -30px; }
  @media (min-width: 768px) {
    .las_catalinas__image_grid___-1V2xuMyP3KfA8RpReWTmz_ {
      margin: 0px; } }

.las_catalinas__image_grid__image___-1dUTM5b9rzKfXWu350wBi1,
.las_catalinas__image_grid__video___-McQvJIMWByfWOw80l_Jzh {
  height: 40vw;
  max-height: 450px; }
  .las_catalinas__image_grid__image___-1dUTM5b9rzKfXWu350wBi1:first-of-type,
  .las_catalinas__image_grid__video___-McQvJIMWByfWOw80l_Jzh:first-of-type {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    margin-bottom: 2.5%; }
  .las_catalinas__image_grid__image___-1dUTM5b9rzKfXWu350wBi1:nth-of-type(2),
  .las_catalinas__image_grid__video___-McQvJIMWByfWOw80l_Jzh:nth-of-type(2) {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 37.5%;
            flex: 0 0 37.5%;
    margin-right: 2.5%; }
  .las_catalinas__image_grid__image___-1dUTM5b9rzKfXWu350wBi1:nth-of-type(3),
  .las_catalinas__image_grid__video___-McQvJIMWByfWOw80l_Jzh:nth-of-type(3) {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 60%;
            flex: 1 1 60%; }

.las_catalinas__quote___-2AnkM2yJd5YyN5YAb993f8 {
  margin: 100px auto; }
  @media (min-width: 768px) {
    .las_catalinas__quote___-2AnkM2yJd5YyN5YAb993f8 {
      max-width: 1200px; } }

.las_catalinas__quote__text___-1Y9FJnlG0ULrh3jI6bayyg {
  position: relative;
  font-family: "Baskerville", serif;
  font-size: 2.5rem;
  line-height: 2.8125rem; }
  .las_catalinas__quote__text___-1Y9FJnlG0ULrh3jI6bayyg:before {
    content: "“"; }
  .las_catalinas__quote__text___-1Y9FJnlG0ULrh3jI6bayyg:after {
    content: "”"; }

.las_catalinas__quote__author___-3Q5_r5_CC3CwvHkaEbJmhF {
  text-align: center;
  margin-top: 20px; }
  .las_catalinas__quote__author___-3Q5_r5_CC3CwvHkaEbJmhF p {
    display: inline-block;
    font-family: "Baskerville", serif;
    font-style: italic;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    position: relative; }
    .las_catalinas__quote__author___-3Q5_r5_CC3CwvHkaEbJmhF p:before {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      left: 0px;
      top: 50%;
      -webkit-transform: translate(-125%, -50%);
              transform: translate(-125%, -50%); }

.las_catalinas__getting_here___-1zqYnaevvuDS3hLem2Tosx {
  display: block;
  margin-bottom: 80px; }
  @media (min-width: 1026px) {
    .las_catalinas__getting_here___-1zqYnaevvuDS3hLem2Tosx {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 120px; } }

.las_catalinas__getting_here__image___-2d8w0u94v7B6gLaOJrWMx3 {
  height: 70vw;
  max-height: 450px;
  margin: 0px -30px; }
  @media (min-width: 768px) {
    .las_catalinas__getting_here__image___-2d8w0u94v7B6gLaOJrWMx3 {
      height: 50vw;
      margin: 0px -50px; } }
  @media (min-width: 1026px) {
    .las_catalinas__getting_here__image___-2d8w0u94v7B6gLaOJrWMx3 {
      height: 40vw;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 35%;
              flex: 0 0 35%;
      margin: 0px 5% 0px 0px; } }

.las_catalinas__getting_here__content___-1GiIUKPGHAtdfColTGdjo- {
  margin-top: 50px;
  text-align: center; }
  @media (min-width: 1026px) {
    .las_catalinas__getting_here__content___-1GiIUKPGHAtdfColTGdjo- {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 60%;
              flex: 1 1 60%;
      margin-top: 0px; } }
