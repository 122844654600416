/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.reveal___-TfrhImqvN9XE_XA3HFLA- {
  overflow-x: hidden;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out; }
  .reveal___-TfrhImqvN9XE_XA3HFLA-.revealed___-2AAdoX6y0rsxNokjHOqQFl {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.reveal_fade___-2LRuW5YIsPliFrsorZC7gL {
  opacity: 0; }

.reveal_down___-LSiA-oCmP5LevoKWOnhrN {
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.reveal_up___-J5tKcFsLOQJQ9AKTt3VXN {
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%); }

.reveal_left___-1CGmyMz8S_FUttruSlpz9Q {
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }

.reveal_right___-36ACsaCyOUnRgMkN3jtPGz {
  -webkit-transform: translate(50%, 0%);
          transform: translate(50%, 0%); }
