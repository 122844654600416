/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.reservation___-2ver4A5VY6__E3NoneIK09 {
  display: block;
  min-height: 50vh; }
  .reservation___-2ver4A5VY6__E3NoneIK09 h1 {
    text-align: center; }

.reservation__container___-3tCBc9YgSSo66Fa6O6sB8y {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .reservation__container___-3tCBc9YgSSo66Fa6O6sB8y {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .reservation__container___-3tCBc9YgSSo66Fa6O6sB8y {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .reservation__container___-3tCBc9YgSSo66Fa6O6sB8y {
      padding-left: 120px;
      padding-right: 120px; } }

.reservation__content___-3RyGe9Iih6gOILbfFZep3r {
  margin-top: 60px; }
  .reservation__content___-3RyGe9Iih6gOILbfFZep3r strong {
    font-family: "Akkurat", sans-serif;
    font-weight: bold; }
  .reservation__content___-3RyGe9Iih6gOILbfFZep3r ul {
    padding-left: 20px; }
    .reservation__content___-3RyGe9Iih6gOILbfFZep3r ul li {
      list-style: disc; }

.reservation__form___-3mZ62GCk6Je5j9LaLfrZ5K {
  width: 100%;
  padding: 10px; }
  @media (min-width: 768px) {
    .reservation__form___-3mZ62GCk6Je5j9LaLfrZ5K {
      max-width: 600px;
      margin: 0px auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 60px; } }

.reservation__form__input___-3czWSwS6P4Re0oedL1Gmm_ {
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-bottom: 15px;
  height: 40px; }
  @media (min-width: 768px) {
    .reservation__form__input___-3czWSwS6P4Re0oedL1Gmm_ {
      margin-bottom: 0px;
      padding-left: 10px;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
      .reservation__form__input___-3czWSwS6P4Re0oedL1Gmm_:first-of-type {
        margin-right: 5px; } }

.reservation__form__submit___-2iLn1hiYoLEKtwautQUaUZ {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 0px;
          flex: 0 0 0px;
  padding: 0px;
  color: #ffffff;
  border: none;
  background: #f6891f;
  height: 40px;
  text-transform: uppercase;
  min-width: 50px;
  display: block;
  text-align: center;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .reservation__form__submit___-2iLn1hiYoLEKtwautQUaUZ:hover {
    opacity: 0.7; }
  @media (min-width: 768px) {
    .reservation__form__submit___-2iLn1hiYoLEKtwautQUaUZ {
      height: auto;
      margin-left: 5px; } }

.reservation__details___-3Cl0oObhvZv7IoXRTN-y2P {
  margin: 0px; }

.reservation__ctas___-1Swx2X2aqI7N2HbJxIO55r {
  padding-bottom: 20px;
  margin-bottom: 30px; }
  .reservation__ctas___-1Swx2X2aqI7N2HbJxIO55r > span[class*="cta"]:nth-child(2) {
    margin-right: 30px; }

@media (min-width: 1026px) {
  .reservation__columns___-ngIuohW7BSaIr3MMOy7gS {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

.reservation__columns__left___-3HF6aVBk9z_w-M5WpZWRsz {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%; }
  @media (min-width: 1026px) {
    .reservation__columns__left___-3HF6aVBk9z_w-M5WpZWRsz {
      margin-right: 8.5%; } }

.reservation__columns__right___-12wQfliNWYMYSKunj-3tm- {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none; }
  @media (min-width: 1026px) {
    .reservation__columns__right___-12wQfliNWYMYSKunj-3tm- {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 40%;
              flex: 1 1 40%; } }

@media (min-width: 1026px) {
  .reservation__reservations_form___-1jlyLMWZ_DlvIWv7tQlsRb {
    position: -webkit-sticky;
    position: sticky;
    top: 170px; } }

.reservation__search_error___-3x6wIcHfh46GmVNRQfGH2L {
  margin-top: 30px; }
  .reservation__search_error___-3x6wIcHfh46GmVNRQfGH2L p {
    text-align: center;
    color: #af0e0e; }

.reservation__reservation_confirmation___-2zGvUxf-Ua_c5u6zNYW2q7 {
  padding: 30px 0px;
  margin-bottom: 30px;
  border-bottom: 2px solid #f2f2f0; }

.reservation__reservation_confirmation__title___-1jrBF5aULGc0p2CXUUCCtC {
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold; }

.reservation__reservation_confirmation__number___-2rDbZdJe-GXCjsXgOgucC8 {
  font-size: 1.6875rem;
  line-height: 1.875rem;
  margin-bottom: 20px; }
