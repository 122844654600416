/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .offer_card___-l8KulXoo2UfGRDtZ9Js28 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background: #f2f2f0;
  border: 1px solid #e2e2e2;
  height: 100%; }
  html:not([class*="accessible"]) .offer_card___-l8KulXoo2UfGRDtZ9Js28:hover .offer_card__image___-9D7EU7NWTAlSmQaK-i_Ms div[class*="image"] {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

html:not([class*="accessible"]) .offer_card__image___-9D7EU7NWTAlSmQaK-i_Ms {
  height: 60vw;
  max-height: 330px;
  overflow: hidden; }
  html:not([class*="accessible"]) .offer_card__image___-9D7EU7NWTAlSmQaK-i_Ms div[class*="image"] {
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    transition: -webkit-transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .offer_card__image___-9D7EU7NWTAlSmQaK-i_Ms {
      min-height: 20vw;
      height: 20vw;
      -webkit-box-flex: 0;
          -ms-flex: none;
              flex: none; } }

html:not([class*="accessible"]) .offer_card__content___-9cH9xhUl4SJrSNuY9d4i2 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 35px;
  text-align: center; }

html:not([class*="accessible"]) .offer_card__cta___-3H00TzFe-MXU89EhRpJ5G9 {
  text-align: center;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: 0px 35px 35px; }
