/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.popup___-2dUCFlcARsJrMXHdyy88p7 {
  display: block; }

.popup__container___-1raH8GiJTrvNGiEOCn9y9C {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.popup__inner___-3M1X_AVZtyDmvd_dzP91ps {
  background: #ffffff;
  max-width: 500px;
  margin: 0px auto; }
  @media (min-width: 768px) {
    .popup__inner___-3M1X_AVZtyDmvd_dzP91ps {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-height: 300px;
      max-width: 900px; } }

.popup__inner__left___-3eOyTQnp9DSqm3kc7F3mhD {
  display: block;
  height: 60vw;
  width: 100%;
  max-height: 540px; }
  @media (min-width: 768px) {
    .popup__inner__left___-3eOyTQnp9DSqm3kc7F3mhD {
      height: auto;
      width: 30vw; } }

.popup__inner__right___-2RL1Vj3YQLQyBcxLKFfL2k {
  position: relative;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 768px) {
    .popup__inner__right___-2RL1Vj3YQLQyBcxLKFfL2k {
      width: 50vw; } }

.popup__cta___-1DxNo0lNw-329FNfcLiv1e {
  margin-top: 30px; }

.popup__close___-3RmC_3PwUYW2FhOPuTQ5Y {
  position: absolute;
  top: 10px;
  right: 8px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }
  .popup__close___-3RmC_3PwUYW2FhOPuTQ5Y > button {
    height: 36px; }
