/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .home___-1s-jmNO6bgxcpuRg_fCcXR {
  min-height: 50vh; }

html:not([class*="accessible"]) .home__container___-3nqSKhom7BONth2KxYvYAl {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .home__container___-3nqSKhom7BONth2KxYvYAl {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__container___-3nqSKhom7BONth2KxYvYAl {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    html:not([class*="accessible"]) .home__container___-3nqSKhom7BONth2KxYvYAl {
      padding-left: 120px;
      padding-right: 120px; } }

html:not([class*="accessible"]) .home__intro___-2jGsrdQ9ZFwqED2Kcvjp27 {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 0px; }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .home__intro___-2jGsrdQ9ZFwqED2Kcvjp27 {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__intro___-2jGsrdQ9ZFwqED2Kcvjp27 {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    html:not([class*="accessible"]) .home__intro___-2jGsrdQ9ZFwqED2Kcvjp27 {
      padding-left: 120px;
      padding-right: 120px; } }

@media (min-width: 1026px) {
  html:not([class*="accessible"]) .home__villas___-3oarxbWrH0yMwxBeEXeKcY {
    margin-top: -30px; } }

@media (min-width: 1300px) {
  html:not([class*="accessible"]) .home__villas___-3oarxbWrH0yMwxBeEXeKcY {
    margin-top: -5vw; } }

html:not([class*="accessible"]) .home__featured_hotels___-3aezJFHZeGjIMJXWXtRtvu {
  text-align: center; }
  html:not([class*="accessible"]) .home__featured_hotels___-3aezJFHZeGjIMJXWXtRtvu h2 {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 0.4rem); }
    @media (min-width: 1026px) {
      html:not([class*="accessible"]) .home__featured_hotels___-3aezJFHZeGjIMJXWXtRtvu h2 {
        font-size: 3.75rem;
        line-height: calc(3.75rem + 0.4rem); } }

html:not([class*="accessible"]) .home__featured_hotels__list___-a0THfCR4ZfuEc8e6zl8f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__featured_hotels__list___-a0THfCR4ZfuEc8e6zl8f {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 48%;
          flex: 1 1 48%;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN:hover {
    opacity: 0.7 !important; }
  html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN:nth-of-type(odd):not(:last-child) {
    margin-right: 4%; }
  html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN a {
    display: block; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 22%;
              flex: 1 1 22%; }
      html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN:nth-child(odd) {
        margin-right: 0; }
      html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN:not(:last-child) {
        margin-right: 2.7%; } }
  html:not([class*="accessible"]) .home__featured_hotels__list__item___-1qipKsXtjVOXNyGaPzb4AN h3 {
    margin-top: 15px;
    font-family: "Akkurat", sans-serif;
    font-size: 1.0625rem;
    line-height: 1.0625rem; }

html:not([class*="accessible"]) .home__featured_hotels__list__item__image___-1jphFuWZca5wYka4jqdxic {
  height: 30vw; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__featured_hotels__list__item__image___-1jphFuWZca5wYka4jqdxic {
      max-height: 380px; } }

@media (min-width: 768px) {
  html:not([class*="accessible"]) .home__las_catalinas___-28VSvgJox1nqkqTRIlSxmr {
    overflow-x: hidden;
    position: relative;
    max-height: 560px;
    height: 50vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; } }

html:not([class*="accessible"]) .home__las_catalinas__image___-1djCDErQU0csx2dasHpt3S {
  height: 60vw; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__las_catalinas__image___-1djCDErQU0csx2dasHpt3S {
      overflow-x: hidden;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 0; } }

html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 50px 20px; }
  html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 h2 {
    font-family: "Akkurat", sans-serif;
    font-weight: 300;
    text-transform: uppercase; }
  html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 p {
    margin-bottom: 30px;
    font-weight: bold; }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 {
      padding: 50px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 {
      padding: 0px;
      max-width: 480px;
      margin: 150px 0px 0px 100px; }
      html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 h2,
      html:not([class*="accessible"]) .home__las_catalinas__content___-TrZgo4tD0nOvJQY8euax4 p {
        color: #ffffff; } }

html:not([class*="accessible"]) .home__group_travel___-uCYuUdybQNY2NqdBbb4vF {
  position: relative;
  max-height: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__group_travel___-uCYuUdybQNY2NqdBbb4vF {
      margin: 0px 50px; } }

html:not([class*="accessible"]) .home__group_travel__image___-1T61fBxeAj0p-wEBb2pp9t {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 0; }
  html:not([class*="accessible"]) .home__group_travel__image___-1T61fBxeAj0p-wEBb2pp9t:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3); }

html:not([class*="accessible"]) .home__group_travel__content___-37_iMvDac3_4U2CpveeD8Y {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 50px 20px;
  max-width: 510px;
  margin: 0px auto; }
  html:not([class*="accessible"]) .home__group_travel__content___-37_iMvDac3_4U2CpveeD8Y h2 {
    color: #ffffff; }
  html:not([class*="accessible"]) .home__group_travel__content___-37_iMvDac3_4U2CpveeD8Y p {
    color: #ffffff;
    margin-bottom: 30px;
    font-weight: bold; }

html:not([class*="accessible"]) .home__recommendations___-eBXATR8rpI0dS00MPOZpb {
  position: relative;
  margin: 50px 0px; }
  html:not([class*="accessible"]) .home__recommendations___-eBXATR8rpI0dS00MPOZpb h2 {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 0.4rem);
    text-align: center; }
    @media (min-width: 1026px) {
      html:not([class*="accessible"]) .home__recommendations___-eBXATR8rpI0dS00MPOZpb h2 {
        font-size: 3.75rem;
        line-height: calc(3.75rem + 0.4rem); } }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__recommendations___-eBXATR8rpI0dS00MPOZpb {
      margin: 0px;
      padding: 70px 0px 50px; } }

html:not([class*="accessible"]) .home__recommendations__images___-3inOjfvBUeHLNPii_j37zO {
  position: relative;
  overflow: hidden; }
  html:not([class*="accessible"]) .home__recommendations__images___-3inOjfvBUeHLNPii_j37zO h3 {
    color: white;
    text-transform: uppercase;
    z-index: 1;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 1.25rem;
    line-height: 1.5rem; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__recommendations__images___-3inOjfvBUeHLNPii_j37zO {
      padding: 30px;
      max-height: 750px;
      height: 70vw; } }

html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe {
  display: block;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  height: 50vw;
  position: relative;
  overflow: hidden;
  height: 60vw;
  display: block;
  margin-bottom: 20px; }
  html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe:hover {
    opacity: 0.7; }
  html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe:not(:last-child) {
    margin-bottom: 30px; }
  html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe > div {
    height: 100%;
    width: 100%; }
    html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe > div:after {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3); }
  html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe:hover div[class*="image"] {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe div[class*="image"] {
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe {
      position: absolute;
      height: auto;
      margin-bottom: 0px; }
      html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe:not(:last-child) {
        margin-bottom: 0px; }
      html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe._0___-2hiCKgwKM5bUt7UZUYzyvo {
        left: 0px;
        top: 0px;
        width: 49%;
        height: 100%; }
      html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe._1___-2qICer88aaip-p7djQLueU {
        right: 0px;
        top: 0px;
        width: 49%;
        height: 63.5%; }
      html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe._2___-1OSsvCFYiWtGdZZJWjBNT4 {
        left: 51%;
        bottom: 0%;
        width: 23.5%;
        height: 33.5%; }
      html:not([class*="accessible"]) .home__recommendations__image___-1L4_1zwFA9RnySbrwMXAIe._3___-3RRkOUhI25vH02H0ZVxnGu {
        right: 0%;
        bottom: 0%;
        width: 23.5%;
        height: 33.5%; } }

html:not([class*="accessible"]) .home__quotes___-1QXfCIJ84_RWsFlLWcmz0e {
  margin-bottom: -30px; }

html:not([class*="accessible"]) .home__offers___-3rfgZ6bhB1mGuvMEj-LdbY {
  text-align: center;
  margin: 0px 0px 70px; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__offers___-3rfgZ6bhB1mGuvMEj-LdbY {
      margin: 70px 50px; } }
  html:not([class*="accessible"]) .home__offers___-3rfgZ6bhB1mGuvMEj-LdbY h2 {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 0.4rem); }
    @media (min-width: 1026px) {
      html:not([class*="accessible"]) .home__offers___-3rfgZ6bhB1mGuvMEj-LdbY h2 {
        font-size: 3.75rem;
        line-height: calc(3.75rem + 0.4rem); } }

html:not([class*="accessible"]) .home__offers__list___-2kaLRHTaLQTskuqPK0h3K9 {
  display: block; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__offers__list___-2kaLRHTaLQTskuqPK0h3K9 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

html:not([class*="accessible"]) .home__offers__list__item___-RLbIfGA5UZFVrrhQK9458 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  html:not([class*="accessible"]) .home__offers__list__item___-RLbIfGA5UZFVrrhQK9458:not(:last-child) {
    margin-bottom: 30px; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .home__offers__list__item___-RLbIfGA5UZFVrrhQK9458:not(:last-child) {
      margin-bottom: 0px; }
    html:not([class*="accessible"]) .home__offers__list__item___-RLbIfGA5UZFVrrhQK9458:not(:last-child) {
      margin-right: 2.5%; } }

html:not([class*="accessible"]) .home__booking_widget___-1eElqLcw2sOuT5zDOy9tBt {
  display: none; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .home__booking_widget___-1eElqLcw2sOuT5zDOy9tBt {
      display: block;
      margin-top: 50px; } }

html[class*="accessible"] .home__featured_hotels___-3aezJFHZeGjIMJXWXtRtvu {
  text-align: center;
  margin: 50px 0px 20px; }

html[class*="accessible"] .home__recommendations___-eBXATR8rpI0dS00MPOZpb {
  text-align: center;
  margin: 50px 0px 20px; }

html[class*="accessible"] .home__las_catalinas___-28VSvgJox1nqkqTRIlSxmr,
html[class*="accessible"] .home__group_travel___-uCYuUdybQNY2NqdBbb4vF,
html[class*="accessible"] .home__offers___-3rfgZ6bhB1mGuvMEj-LdbY {
  margin: 50px 0px; }

html[class*="accessible"] .home__offers__list__item___-RLbIfGA5UZFVrrhQK9458 {
  margin-bottom: 20px; }
