/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.room_pdf___-1mZHxWE_SrSr0YIvRAJpnC {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
  overflow: scroll;
  page-break-inside: auto; }

.room_pdf__container___-2XNOPSXd1XwHyEuGWSBnPd {
  max-width: 1300px;
  margin: 0px; }

.room_pdf__detail_page___-1NrgT65_9KLlodvM_HxcnD {
  background: white;
  padding: 20px;
  position: relative;
  height: 1050px;
  width: 1300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll; }

.room_pdf__floorplan___-1SkgPJKQfXOvZswHapaZzZ {
  background: white;
  padding: 50px;
  position: relative;
  height: 1000px;
  width: 1300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: scroll; }

.room_pdf__floorplan__caption___-Z1W6uFP_h2IoPLZXYhGQU {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  padding: 0px 50px;
  bottom: 100px; }
  .room_pdf__floorplan__caption___-Z1W6uFP_h2IoPLZXYhGQU p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: "Baskerville", serif; }

.room_pdf__floorplan__image___-QvZ_XxdLSi5-d5RH4l-f3 {
  height: 700px;
  width: 1000px;
  position: relative;
  top: -50px; }
  .room_pdf__floorplan__image___-QvZ_XxdLSi5-d5RH4l-f3 .image_proxy___-142yZs-bkpNVYVJVJt4yvf {
    -o-object-fit: contain;
       object-fit: contain;
    height: 100%;
    width: 100%; }

.room_pdf__footer___-scB-XN65C6al89q0D2pw {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #181818;
  text-align: center; }
  .room_pdf__footer___-scB-XN65C6al89q0D2pw strong {
    font-family: "Akkurat", sans-serif;
    font-weight: bold; }
  .room_pdf__footer___-scB-XN65C6al89q0D2pw p {
    padding: 15px;
    color: #ffffff; }

.room_pdf__page_1_right___-FEDVa-qJZG8HTC-1T2PNa {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
  overflow: visible; }

.room_pdf__page_1_left___-16DQyVILZT7V1qirEm3q7Q {
  margin-right: 40px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%; }
  .room_pdf__page_1_left___-16DQyVILZT7V1qirEm3q7Q .image_proxy___-142yZs-bkpNVYVJVJt4yvf {
    -o-object-fit: cover;
       object-fit: cover;
    height: 400px !important;
    width: 100%; }
    .room_pdf__page_1_left___-16DQyVILZT7V1qirEm3q7Q .image_proxy___-142yZs-bkpNVYVJVJt4yvf:first-child {
      margin-bottom: 20px; }

.room_pdf__subtitle___-2emY-4R9jvwz9GmCVlNokn {
  font-family: "Akkurat", sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-transform: uppercase; }

.room_pdf__title___-32KVY_XnbWx37vDDnOYdmu {
  font-size: 2.5rem;
  line-height: 2.5rem; }

.room_pdf__content___-1v0NK6qfcfqVPwBuVOEfOz {
  overflow: visible; }
  .room_pdf__content___-1v0NK6qfcfqVPwBuVOEfOz h4 {
    font-family: "Akkurat", sans-serif;
    font-size: 1.125rem;
    line-height: 1.125rem; }
  .room_pdf__content___-1v0NK6qfcfqVPwBuVOEfOz p {
    font-size: 1rem;
    line-height: 1.4375rem; }

.room_pdf__svg_img___-FNdY9RCBhzNp_oRBXKtbo {
  height: 8px;
  width: 8px; }

.room_pdf__amenities___-3jHYuluiUCqd63LHmFAXs5 {
  background: #f2f2f0;
  padding: 25px;
  margin: 0px 40px 50px 0px; }

.room_pdf__amenities__title_wrapper___-1aZiHs196gQ1yKonFS0gUE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.room_pdf__amenities__decor___-3L0vQSxgzymX7NyaWBOr0_ {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  height: 1px;
  background-color: #e2e2e2; }

.room_pdf__amenities__title___-22JdMguc7Olmo54gHBSEmT {
  margin: 0px 0px 30px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  padding: 0px; }

.room_pdf__amenities__inner___-KALRlsNesb3DxZa6tC4PK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.room_pdf__amenities__item__title___-3vCn2sF7wITkAuUPj8oDxH {
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  margin: 5px 0px 15px; }

.room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin-bottom: 10px; }
  .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 46%;
            flex: 0 0 46%; }
    .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h:nth-child(2n) {
      margin-left: 8%; }
    @media (min-width: 768px) {
      .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 31.6669%;
                flex: 0 0 31.6669%; }
        .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h:nth-child(2n) {
          margin-left: 0; }
        .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h:not(:nth-child(3n)) {
          margin-right: 2.5%; } }
    .room_pdf__amenities__item___-1nqo0Rw2Ob8ZVPNfDqrrDM.short___-25mOZLnZ7AC_E22h_JCH2h h3 {
      font-family: "Akkurat", sans-serif;
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 0.75rem;
      max-width: 80%; }

.room_pdf__page_2___-2CUFc3kw1JI1DbaWljn_Ig {
  height: 1000px; }

.room_pdf__page_2_left___-1M28VWfMGaQNJ2pJVnjFTu,
.room_pdf__page_2_right___-1KUGCmlWd7kByNcXWxyUF2 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  overflow: visible; }

.room_pdf__page_2_left___-1M28VWfMGaQNJ2pJVnjFTu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-height: 800px; }

.room_pdf__page_2_right__images___-1xP0SXEI1OCeODApqjzMe0 > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .room_pdf__page_2_right__images___-1xP0SXEI1OCeODApqjzMe0 > div .image_proxy___-142yZs-bkpNVYVJVJt4yvf {
    height: 200px;
    width: 49.2%;
    -o-object-fit: cover;
       object-fit: cover;
    display: block; }

.room_pdf__page_2_right__images___-1xP0SXEI1OCeODApqjzMe0 > .image_proxy___-142yZs-bkpNVYVJVJt4yvf {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  display: block; }

.room_pdf__page_2_right__content___-2kyoFtm6AO8fD261dNs3oB {
  max-height: 600px;
  margin-top: 20px; }
  .room_pdf__page_2_right__content___-2kyoFtm6AO8fD261dNs3oB p {
    font-size: 0.75rem;
    line-height: 0.9375rem; }
  .room_pdf__page_2_right__content___-2kyoFtm6AO8fD261dNs3oB strong {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    margin-bottom: 6px;
    display: block; }

.room_pdf__page_2_left__content___-1L0AtPYMxfP3xng46fPgbh {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.mask___-prVf6K1wf-89X46S_YzPv {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding: 50px; }

.download___-3LgvN_Eha5u2zotfTwewWB {
  max-width: 500px;
  margin: 0px auto;
  background: #f2f2f0;
  padding: 70px 50px;
  -webkit-box-shadow: 1px 1px 2px #ccc;
          box-shadow: 1px 1px 2px #ccc;
  height: 300px;
  position: relative; }

.download__loader___-2p4VAi3JhZvgdV3x2ThbJS {
  margin-bottom: 20px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }
  .download__loader___-2p4VAi3JhZvgdV3x2ThbJS p {
    position: relative;
    top: -30px;
    font-size: 0.75rem;
    line-height: 0.75rem; }

.download__button___-Ma0qNpaC11UWCNOlfGTd- {
  margin-bottom: 20px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }

.download__text___-f-VKpswpdY8ON3o71xNGc {
  margin-bottom: 30px;
  font-size: 1rem;
  line-height: 1.25rem; }

.room_pdf__page_2_right__content_wrapper___-33DT9lZ98lAu2mOFdqxocC {
  position: relative;
  padding-left: 20px; }
  .room_pdf__page_2_right__content_wrapper___-33DT9lZ98lAu2mOFdqxocC img {
    position: absolute;
    top: 2px;
    left: 0px;
    height: 14px; }

.room_pdf__capture_container___-U6ICYb1ltnTKB1blVgcvR img {
  display: block; }
