/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.cancelled_reservation___-YN02ac9jx_5BrneIzNkvq h3,
.cancelled_reservation___-YN02ac9jx_5BrneIzNkvq h4 {
  color: #af0e0e; }

.cancelled_reservation__hero___--DzI_WwsDuf_aqayw42M3 {
  height: 400px;
  width: 100%; }
