/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.landing_page___-JEyjZ-IoC2_yfX-v2R4Ot {
  display: block;
  overflow-x: hidden; }

.landing_page__container___-1G8Q37IIGlFtADiK70WKX7 {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 45px;
  padding-bottom: 45px; }
  @media (min-width: 480px) {
    .landing_page__container___-1G8Q37IIGlFtADiK70WKX7 {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .landing_page__container___-1G8Q37IIGlFtADiK70WKX7 {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .landing_page__container___-1G8Q37IIGlFtADiK70WKX7 {
      padding-left: 120px;
      padding-right: 120px; } }
  @media (min-width: 768px) {
    .landing_page__container___-1G8Q37IIGlFtADiK70WKX7 {
      padding-top: 60px;
      padding-bottom: 60px; } }

.landing_page__booking_widget___-3CFFjSuHLEXt7wEig85mC2 {
  display: none; }
  @media (min-width: 768px) {
    .landing_page__booking_widget___-3CFFjSuHLEXt7wEig85mC2 {
      display: block;
      margin-top: 50px; } }

.landing_page__intro___-1cenJFm17S3M15hFBkRLfd {
  margin: 0px 0px 30px; }

.landing_page__experiences___-1YkwuITpmxOPi4gwZp7ti2 {
  display: block;
  margin-top: 30px; }
  .landing_page__experiences___-1YkwuITpmxOPi4gwZp7ti2 h2 {
    text-align: center;
    font-size: 3.4375rem;
    line-height: 3.4375rem;
    margin-bottom: 50px; }

.landing_page__experiences__container___-1UEip4ldVrN4fLyUvAn-d1 {
  width: 100%;
  position: relative; }
  @media (min-width: 768px) {
    .landing_page__experiences__container___-1UEip4ldVrN4fLyUvAn-d1 {
      height: 90vw; } }

.landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf {
  height: 50vw;
  width: 100%;
  margin-bottom: 30px;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:hover {
    opacity: 0.7; }
  .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf a {
    height: 100%;
    width: 100%;
    display: block;
    position: relative; }
  .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf h3 {
    color: white;
    text-transform: uppercase;
    z-index: 1;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 0px 10px;
    font-size: 1.25rem;
    line-height: 1.5rem; }
  .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none; }
  @media (min-width: 768px) {
    .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf {
      position: absolute;
      margin-bottom: 0px; }
      .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:nth-of-type(1) {
        top: 0px;
        left: 0px;
        height: 37%;
        width: 30%; }
      .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:nth-of-type(2) {
        top: 0px;
        right: 0px;
        height: 37%;
        width: 68%; }
      .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:nth-of-type(3) {
        top: 39%;
        left: 0px;
        height: 35%;
        width: 49%; }
      .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:nth-of-type(4) {
        top: 76%;
        left: 0px;
        height: 24%;
        width: 49%; }
      .landing_page__experiences__card___-2zNLOqJXnLdx3zHdLaDWhf:nth-of-type(5) {
        top: 39%;
        right: 0px;
        height: 61%;
        width: 49%; } }

.landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"],
.landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"] {
  height: 30px;
  width: 30px;
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #7f7f7f; }
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"]:before,
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"]:before {
    border-color: #7f7f7f;
    height: 10px;
    width: 10px; }

.landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"] {
  right: -15px; }
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"]:before {
    right: auto;
    left: 6px; }

.landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"] {
  left: -15px; }
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"]:before {
    left: auto;
    right: 6px; }

@media (min-width: 600px) {
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"],
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"] {
    height: 40px;
    width: 40px; }
    .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"]:before,
    .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"]:before {
      height: 13px;
      width: 13px; }
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"] {
    right: -25px; }
    .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-next"]:before {
      left: 9px; }
  .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"] {
    left: -25px; }
    .landing_page__quotes___-2aHt9T3RrjdEsD67M_Uy1f button[class*="slick-prev"]:before {
      right: 9px; } }

.landing_page__image_text___-1lcfspO01oXXdyVh55F2z7 div[class*="content"] p,
.landing_page__image_text___-1lcfspO01oXXdyVh55F2z7 div[class*="content"] li,
.landing_page__image_text___-1lcfspO01oXXdyVh55F2z7 div[class*="content"] span {
  font-size: 1rem !important; }
