/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) {
  /**
**
** ADA
**
**/ }
  html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 {
    width: calc(100%);
    margin: 0px auto;
    height: 40vh;
    -webkit-transition: 350ms height ease-in-out;
    transition: 350ms height ease-in-out;
    position: relative;
    z-index: 1;
    position: relative; }
    html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7:after {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0);
      background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.3)));
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 80%); }
    @media (min-width: 768px) {
      html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 {
        height: 50vw;
        max-height: 75vh; } }
  @media (min-width: 768px) and (max-height: 850px) {
    html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 {
      height: 450px; } }
  @media (min-width: 768px) and (max-height: 650px) {
    html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 {
      height: 300px; } }
    html:not([class*="accessible"]) .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7.mask___-22Rt35sWoMAQwDU049HmM0:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3); }
  html:not([class*="accessible"]) .hero_carousel__content_container___-3A-ipmgqb72lWHD4MWD5H7 {
    z-index: 2;
    text-align: center;
    position: absolute;
    left: 5%;
    right: 5%;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    padding: 0;
    max-width: 1400px;
    margin: 0px auto; }
    @media (min-width: 768px) {
      html:not([class*="accessible"]) .hero_carousel__content_container___-3A-ipmgqb72lWHD4MWD5H7 {
        left: 10%;
        right: 10%; } }
    @media (min-width: 1300px) {
      html:not([class*="accessible"]) .hero_carousel__content_container___-3A-ipmgqb72lWHD4MWD5H7 {
        left: 20%;
        right: 20%; } }
  html:not([class*="accessible"]) .hero_carousel__title___-1YvMaK9jpXnhjI4WzV_OuW {
    max-width: 700px;
    margin: 0px auto; }
  html:not([class*="accessible"]) .hero_carousel__title___-1YvMaK9jpXnhjI4WzV_OuW,
  html:not([class*="accessible"]) .hero_carousel__subtitle___-1Iknc4Md_wNSpcPfYtl15K,
  html:not([class*="accessible"]) .hero_carousel__blurb___-2QHrxyD1nKnsgxDPUqvE7V {
    white-space: pre-line;
    color: #ffffff; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .hero_carousel__blurb___-2QHrxyD1nKnsgxDPUqvE7V {
      margin-top: 20px;
      font-size: 20px; } }
  html:not([class*="accessible"]) .hero_carousel__title___-1YvMaK9jpXnhjI4WzV_OuW {
    margin-bottom: 0; }
  html:not([class*="accessible"]) .hero_carousel__subtitle___-1Iknc4Md_wNSpcPfYtl15K {
    text-transform: uppercase; }
  html:not([class*="accessible"]) .hero_carousel__carousel_container___-3apidxg1RVICi3uT8iyfwL {
    height: 100%;
    position: relative; }
    @media (min-width: 1026px) {
      html:not([class*="accessible"]) .hero_carousel__carousel_container___-3apidxg1RVICi3uT8iyfwL {
        height: 100%; }
        html:not([class*="accessible"]) .hero_carousel__carousel_container___-3apidxg1RVICi3uT8iyfwL:after {
          height: 250px; } }
  html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C {
    position: relative; }
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__content_container___-3A-ipmgqb72lWHD4MWD5H7 {
      text-align: center;
      padding: 20px; }
      @media (768px) {
        html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__content_container___-3A-ipmgqb72lWHD4MWD5H7 {
          width: 100%; } }
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__title___-1YvMaK9jpXnhjI4WzV_OuW,
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__subtitle___-1Iknc4Md_wNSpcPfYtl15K,
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__blurb___-2QHrxyD1nKnsgxDPUqvE7V {
      color: #000000;
      white-space: pre-line; }
    @media (min-width: 1026px) {
      html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__blurb___-2QHrxyD1nKnsgxDPUqvE7V {
        margin-top: 20px;
        font-size: 20px; } }
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__title___-1YvMaK9jpXnhjI4WzV_OuW {
      margin-bottom: 0; }
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__subtitle___-1Iknc4Md_wNSpcPfYtl15K {
      text-transform: uppercase; }
    html:not([class*="accessible"]) .hero_carousel__ada___-1cmgwA5nCKxDMs0izqr70C .hero_carousel__carousel_container___-3apidxg1RVICi3uT8iyfwL {
      width: 100%; }

html[class*="accessible"] .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 {
  background: #f2f2f0 !important; }
  html[class*="accessible"] .hero_carousel___-1k4RnbOg1MCpqrgKHJGmU7 h1 {
    padding: 80px; }
