/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.text_and_grid__container___-3w8dOjrQa1SD9VW73a6FcA {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .text_and_grid__container___-3w8dOjrQa1SD9VW73a6FcA {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .text_and_grid__container___-3w8dOjrQa1SD9VW73a6FcA {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .text_and_grid__container___-3w8dOjrQa1SD9VW73a6FcA {
      padding-left: 120px;
      padding-right: 120px; } }

.text_and_grid__carousel___-2dTirIOLj07Isfm1w0NnXD {
  width: calc(100%);
  margin: 0px auto;
  height: 40vh;
  -webkit-transition: 350ms height ease-in-out;
  transition: 350ms height ease-in-out;
  position: relative;
  z-index: 1;
  position: relative; }
  .text_and_grid__carousel___-2dTirIOLj07Isfm1w0NnXD:after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.3)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 80%); }
  @media (min-width: 768px) {
    .text_and_grid__carousel___-2dTirIOLj07Isfm1w0NnXD {
      height: 50vw;
      max-height: 75vh; } }
  @media (min-width: 768px) and (max-height: 850px) {
    .text_and_grid__carousel___-2dTirIOLj07Isfm1w0NnXD {
      height: 450px; } }
  @media (min-width: 768px) and (max-height: 650px) {
    .text_and_grid__carousel___-2dTirIOLj07Isfm1w0NnXD {
      height: 300px; } }
