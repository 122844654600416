/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.alternate_rates___-rgf6CVTzg4t1DV29GFC2D {
  display: block; }

.alternate_rates__item___-1B_8WOMUdD49ul3J3Yvj9T {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }

.alternate_rates__item__details___-1zwHqxj6U43gQAvRH8Lxlw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
  margin-right: 40px; }

.alternate_rates__item__details__title___-3sIfl3QjWaVnwWhuN4lhwY {
  text-decoration: underline;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  margin-top: 10px; }

.alternate_rates__item__pricing___-3K-KK_ZWxqvIchHx6i7RJN {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  text-align: right; }
  .alternate_rates__item__pricing___-3K-KK_ZWxqvIchHx6i7RJN h3,
  .alternate_rates__item__pricing___-3K-KK_ZWxqvIchHx6i7RJN p {
    text-align: right; }
  .alternate_rates__item__pricing___-3K-KK_ZWxqvIchHx6i7RJN h3 {
    font-size: 1.875rem;
    line-height: 2.125rem; }
  .alternate_rates__item__pricing___-3K-KK_ZWxqvIchHx6i7RJN p {
    font-size: 0.8125rem;
    line-height: 0.9375rem; }

.alternate_rates__item__cta___-2GZxe3dXAM3nRwMY7CGcu3 {
  margin-top: 20px;
  white-space: pre; }

p.alternate_rates__item__selected_text___-1UIs8UUnuLcCywgEQega7g {
  margin-top: 20px; }
  p.alternate_rates__item__selected_text___-1UIs8UUnuLcCywgEQega7g svg path {
    fill: green; }
