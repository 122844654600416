/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.modal___-1w5cA3J5J37kE7NNtGD7ZU {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 25px; }
  .modal___-1w5cA3J5J37kE7NNtGD7ZU.grey___-3U2wvQr2w8eJV90aJtbjkS {
    background: #f2f2f0; }
  .modal___-1w5cA3J5J37kE7NNtGD7ZU.white___-bFkV6Q8t1Cq0jzZ-p5gLd {
    background: #fff; }
  @media (min-width: 768px) {
    .modal___-1w5cA3J5J37kE7NNtGD7ZU {
      padding: 50px; } }
  @media (min-width: 1026px) {
    .modal___-1w5cA3J5J37kE7NNtGD7ZU {
      padding: 80px;
      overflow: hidden; } }
  @media (min-width: 1300px) {
    .modal___-1w5cA3J5J37kE7NNtGD7ZU {
      padding: 100px 150px 120px; } }
  @media (min-width: 1026px) {
    .modal___-1w5cA3J5J37kE7NNtGD7ZU {
      overflow: hidden; } }

.modal__container___-1KJbwxvWyrx9TLeltjvygK {
  position: relative;
  max-width: 1300px;
  margin: 0px auto;
  height: calc(100% - 50px);
  overflow: visible;
  -ms-overflow-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 768px) {
    .modal__container___-1KJbwxvWyrx9TLeltjvygK {
      height: 100%; } }

.modal__close___-2WfzQ1NzOdQznRa04W46A {
  position: relative;
  width: 100%; }
  .modal__close___-2WfzQ1NzOdQznRa04W46A button[class*="hamburger"] {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out; }
    .modal__close___-2WfzQ1NzOdQznRa04W46A button[class*="hamburger"] div[class*="line"] {
      height: 3px;
      width: 75%;
      left: 6px;
      background-color: #7f7f7f; }
    .modal__close___-2WfzQ1NzOdQznRa04W46A button[class*="hamburger"]:hover {
      opacity: 0.7; }
    @media (min-width: 768px) {
      .modal__close___-2WfzQ1NzOdQznRa04W46A button[class*="hamburger"] {
        top: 15px;
        right: 15px; } }

html[class*="accessible"] .modal__container___-1KJbwxvWyrx9TLeltjvygK {
  background-color: #ffffff; }
