/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.default___-1U0tUvPuC6d53AYEAKY-kl {
  display: block; }
  .default___-1U0tUvPuC6d53AYEAKY-kl h1 {
    text-align: center; }

.default__container___-326xswTDFDv6oHmJ-Kabri {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .default__container___-326xswTDFDv6oHmJ-Kabri {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .default__container___-326xswTDFDv6oHmJ-Kabri {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .default__container___-326xswTDFDv6oHmJ-Kabri {
      padding-left: 120px;
      padding-right: 120px; } }

.default__content___-G0sOrZIxrFaej7v8XsZUO {
  margin-top: 60px; }
  .default__content___-G0sOrZIxrFaej7v8XsZUO strong {
    font-family: "Akkurat", sans-serif;
    font-weight: bold; }
  .default__content___-G0sOrZIxrFaej7v8XsZUO ul {
    padding-left: 20px; }
    .default__content___-G0sOrZIxrFaej7v8XsZUO ul li {
      list-style: disc; }

.default__error_container___-2U0Pswio08SJC_pIVC1whd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 60vh; }

.default__error_content___-2KNRoit5H6Zhf6sZKYmoNd {
  max-width: 500px;
  margin: 0px auto;
  text-align: center; }
