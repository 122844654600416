/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.experiences___-12T06xoCiEJJ5FpxaHJj0C {
  display: block; }

.experiences__container___-BAJJYNHEmTL8AOLcu0JlF {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 30px;
  overflow-x: hidden; }
  @media (min-width: 480px) {
    .experiences__container___-BAJJYNHEmTL8AOLcu0JlF {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .experiences__container___-BAJJYNHEmTL8AOLcu0JlF {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .experiences__container___-BAJJYNHEmTL8AOLcu0JlF {
      padding-left: 120px;
      padding-right: 120px; } }

.experiences__select___-242_Vni3Wu4HQwprzrLt21 {
  background: #f2f2f0;
  max-width: 400px;
  margin: 30px auto 0px; }

.experiences__media___-2YaiArU-3Ph34hOw5Hcn0q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40vw;
  max-height: 530px; }

.experiences__media__image___-1fogyti3Rb3i3TAdBhYgQH {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  margin-right: 5%; }
  @media (min-width: 768px) {
    .experiences__media__image___-1fogyti3Rb3i3TAdBhYgQH {
      margin-right: 2%; } }
  .experiences__media__image___-1fogyti3Rb3i3TAdBhYgQH:nth-child(2) {
    margin-right: 0%; }

.experiences__media__video___-3MePB-z0810UXlrCRjRGKP {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 75%;
          flex: 1 1 75%; }

.experiences__filtered_list___-1hMltpjb5wO9LXO-CM7KE2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.experiences__filtered_list__image___-1wOOFroVRfl0iTK7cWLyst {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48.75%;
          flex: 0 0 48.75%;
  height: 39vw;
  margin-bottom: 2.5%;
  position: relative;
  overflow: hidden;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .experiences__filtered_list__image___-1wOOFroVRfl0iTK7cWLyst:nth-child(2n) {
    margin-left: 2.5%; }
  .experiences__filtered_list__image___-1wOOFroVRfl0iTK7cWLyst h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #ffffff;
    text-transform: uppercase;
    z-index: 1;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem; }
  .experiences__filtered_list__image___-1wOOFroVRfl0iTK7cWLyst:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); }
  .experiences__filtered_list__image___-1wOOFroVRfl0iTK7cWLyst:hover {
    opacity: 0.7; }
