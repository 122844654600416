/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.offer_detail___-iaSvz_9S2q7Eb2165iWk_ {
  display: block; }

.offer_detail__container___-OVE3Py-kt4ARsIK6F2fUV {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 30px; }
  @media (min-width: 480px) {
    .offer_detail__container___-OVE3Py-kt4ARsIK6F2fUV {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .offer_detail__container___-OVE3Py-kt4ARsIK6F2fUV {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .offer_detail__container___-OVE3Py-kt4ARsIK6F2fUV {
      padding-left: 120px;
      padding-right: 120px; } }
