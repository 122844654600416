/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.gallery_modal___-38v5yKHMUAHhcd4NUOsFUQ button[class*="hamburger"] {
  top: -35px;
  right: -5px;
  height: 50px;
  width: 50px; }
  .gallery_modal___-38v5yKHMUAHhcd4NUOsFUQ button[class*="hamburger"] div[class*="line"] {
    background-color: white; }

.gallery_modal___-38v5yKHMUAHhcd4NUOsFUQ div[class*="image image--background"] {
  background-size: contain; }
