/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.view_reservation__reservation___-1vR4Vz1PvPl9Ir7iSRSHuT {
  padding: 30px 0px;
  margin-bottom: 30px;
  border-bottom: 2px solid #f2f2f0; }

.view_reservation__reservation__title___-3422YmpIx31AR_XqAJKTjv {
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold; }

.view_reservation__reservation__number___-2elXP9opIumKVr0vXTnIg4 {
  font-size: 1.6875rem;
  line-height: 1.875rem;
  margin-bottom: 20px; }

.view_reservation__hotel_details___-1VLiLViJPSlju9jR6sYuAA {
  padding: 30px 0px 50px;
  margin-bottom: 30px;
  border-bottom: 2px solid #f2f2f0; }

.view_reservation__row___-3o9Tq6CnY3iHSHD2EuSEUZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }

.view_reservation__guest_details___-3qMfPYJt2zipLxWfwF9s0J {
  display: block; }

.view_reservation__terms___-1f7BWCXJ-99sJCnYEgKSh1 {
  padding: 30px 0px 50px;
  border-bottom: 2px solid #f2f2f0; }
  .view_reservation__terms___-1f7BWCXJ-99sJCnYEgKSh1 h6:not(:first-of-type) {
    margin-top: 20px; }

.view_reservation__contact___-257Hk7nMArux8jDB-y9vz0 {
  margin-top: 50px; }
  .view_reservation__contact___-257Hk7nMArux8jDB-y9vz0 p:first-of-type {
    margin-bottom: 10px; }
  .view_reservation__contact___-257Hk7nMArux8jDB-y9vz0 a {
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out; }
    .view_reservation__contact___-257Hk7nMArux8jDB-y9vz0 a:hover {
      opacity: 0.7; }
