/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.room___-1cNegiDoZxXsVtQ0QJbM-9 {
  display: block; }

.room__container___-SIIate0T_PCxGi1ddSuUN {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative; }
  @media (min-width: 480px) {
    .room__container___-SIIate0T_PCxGi1ddSuUN {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .room__container___-SIIate0T_PCxGi1ddSuUN {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .room__container___-SIIate0T_PCxGi1ddSuUN {
      padding-left: 120px;
      padding-right: 120px; } }

.room__columns___-3y0ClX0Bg1T3Tv8J6CVYyk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 1180px) {
    .room__columns___-3y0ClX0Bg1T3Tv8J6CVYyk {
      padding: 0px 70px; } }

.room__columns__left___-xlF7kVmzd1Zg3K9p2L75T {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
  position: relative; }
  @media (min-width: 1026px) {
    .room__columns__left___-xlF7kVmzd1Zg3K9p2L75T {
      margin-right: 8.5%; } }
  .room__columns__left___-xlF7kVmzd1Zg3K9p2L75T.room__private___-1XUvV88bKzbhg4adwzAk-p {
    margin-right: 0px; }
    @media (min-width: 768px) {
      .room__columns__left___-xlF7kVmzd1Zg3K9p2L75T.room__private___-1XUvV88bKzbhg4adwzAk-p h1 {
        max-width: 70%; } }
    @media (min-width: 1026px) {
      .room__columns__left___-xlF7kVmzd1Zg3K9p2L75T.room__private___-1XUvV88bKzbhg4adwzAk-p h1 {
        max-width: 80%; } }

.room__columns__right___-2_F9wC41eyvhg1DODi_7Iy {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none; }
  @media (min-width: 1026px) {
    .room__columns__right___-2_F9wC41eyvhg1DODi_7Iy {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 40%;
              flex: 1 1 40%;
      padding-bottom: 50px; } }
  .room__columns__right___-2_F9wC41eyvhg1DODi_7Iy.room__private___-1XUvV88bKzbhg4adwzAk-p {
    display: none; }

.room__subtitle___-3gnawQ3uaWkAfO7QY1Jpow {
  color: #7f7f7f;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase; }

.room__reservations___-3u0lP2eT8Waf1LgvKTRjfd {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background: #f2f2f0;
  border-top: 1px solid #e2e2e2;
  padding: 10px;
  z-index: 2; }
  @media (min-width: 1026px) {
    .room__reservations___-3u0lP2eT8Waf1LgvKTRjfd {
      position: -webkit-sticky;
      position: sticky;
      top: 160px;
      padding: 0px;
      border: none;
      background: transparent;
      z-index: 0; } }

.room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  margin: 0px 0px 50px; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK ul {
    padding-left: 20px; }
    .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK ul li {
      list-style: disc; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK ol {
    padding-left: 20px; }
    .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK ol li {
      list-style: decimal; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK svg {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 3px; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK > div {
    margin-left: 20px; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK h4 {
    margin-bottom: 10px;
    font-size: 1.4375rem;
    line-height: 1.5rem; }
  .room__disclaimer___-3UzDsq-f9-AcifzYtHvwPK p {
    font-size: 0.8125rem;
    line-height: 1.375rem; }

.room__media___-3eIIYNAaBVzWu3Y91HN0qt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0px -20px; }
  @media (min-width: 480px) {
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt {
      margin: 0px -30px; } }
  @media (min-width: 768px) {
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt {
      margin: 0px -50px; } }
  .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:first-child {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    height: 50vw;
    margin-bottom: 2.5%; }
  .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:nth-child(2) {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 48.75%;
            flex: 1 1 48.75%;
    margin-right: 2.5%;
    height: 30vw; }
  .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:nth-child(3) {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 48.75%;
            flex: 1 1 48.75%;
    height: 30vw; }
  @media (min-width: 1026px) {
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt {
      margin: 0px; }
      .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:first-child {
        max-height: 375px; }
      .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:nth-child(2) {
        height: 190px; }
      .room__media___-3eIIYNAaBVzWu3Y91HN0qt > div:nth-child(3) {
        height: 190px; } }
  @media (min-width: 1026px) and (min-width: 1026px) {
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt.room__is_private___-14k5xFqWIWPrI85kX3-_JC > div:first-child {
      max-height: 425px;
      height: 35vw; }
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt.room__is_private___-14k5xFqWIWPrI85kX3-_JC > div:nth-child(2) {
      max-height: 375px;
      height: 30vw; }
    .room__media___-3eIIYNAaBVzWu3Y91HN0qt.room__is_private___-14k5xFqWIWPrI85kX3-_JC > div:nth-child(3) {
      max-height: 375px;
      height: 30vw; } }

.room__description___-3rmdubf6LnffEtmtmAymA1 {
  margin-bottom: 50px; }
  .room__description___-3rmdubf6LnffEtmtmAymA1 ul {
    padding-left: 20px; }
    .room__description___-3rmdubf6LnffEtmtmAymA1 ul li {
      list-style: disc; }
  .room__description___-3rmdubf6LnffEtmtmAymA1 ol {
    padding-left: 20px; }
    .room__description___-3rmdubf6LnffEtmtmAymA1 ol li {
      list-style: decimal; }

.room__media__image___-2olsUns6KFwIuzEyxARbLB {
  display: block; }

.room__map___-2e7KTtnQRL4CK73KYRKtFD {
  height: 60vw;
  margin: 0px -20px; }
  @media (min-width: 480px) {
    .room__map___-2e7KTtnQRL4CK73KYRKtFD {
      margin: 0px -30px; } }
  @media (min-width: 768px) {
    .room__map___-2e7KTtnQRL4CK73KYRKtFD {
      margin: 0px -50px; } }
  @media (min-width: 1026px) {
    .room__map___-2e7KTtnQRL4CK73KYRKtFD {
      width: 100%;
      height: 500px;
      margin: 0px; } }

.room__floorplan___-1xd3nSGKDj8gIX83urod1O {
  padding: 30px;
  background: #dde5e6;
  margin-bottom: 80px; }
  @media (min-width: 768px) {
    .room__floorplan___-1xd3nSGKDj8gIX83urod1O {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.room__floorplan__image___-2WGmG6QcCfHzWiLqSZY8dF {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
  margin-right: 5%;
  height: 50vw; }
  .room__floorplan__image___-2WGmG6QcCfHzWiLqSZY8dF div[class*="image"] {
    background-size: contain; }
  @media (min-width: 768px) {
    .room__floorplan__image___-2WGmG6QcCfHzWiLqSZY8dF {
      height: 260px; } }

.room__floorplan__details___-2hPbMsh8xk3k1psXGeeieR {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 40%;
          flex: 1 1 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.room__floorplan__details_only___-3KsXIZlrKNUaRmsU328I7J {
  margin: -20px 0px 60px; }

.room__other_properties___-1WNGeZ43CQSPACLZAJNrv1 {
  text-align: center;
  background: #e9ece4; }
  .room__other_properties___-1WNGeZ43CQSPACLZAJNrv1 h2 {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 0.4rem); }
    @media (min-width: 1026px) {
      .room__other_properties___-1WNGeZ43CQSPACLZAJNrv1 h2 {
        font-size: 3.75rem;
        line-height: calc(3.75rem + 0.4rem); } }

.room__other_properties__list___-1qSWvK6mJdaS_n6VeUXQb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1026px) {
    .room__other_properties__list___-1qSWvK6mJdaS_n6VeUXQb {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

.room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a:hover {
    opacity: 0.7; }
  .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a:nth-child(odd):not(:last-child) {
    margin-right: 4%; }
  .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a a {
    display: block; }
  @media (min-width: 1026px) {
    .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 22%;
              flex: 1 1 22%; }
      .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a:nth-child(odd) {
        margin-right: 0; }
      .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a:not(:last-child) {
        margin-right: 2.7%; } }
  .room__other_properties__list__item___-349wivlUTF5IXHzUgFkl7a h3 {
    margin-top: 15px;
    font-family: "Akkurat", sans-serif;
    font-size: 1.0625rem;
    line-height: 1.0625rem; }

.room__other_properties__list__item__image___-1hdCrO04MewV9rxsN3TbZ1 {
  height: 30vw; }
  @media (min-width: 1026px) {
    .room__other_properties__list__item__image___-1hdCrO04MewV9rxsN3TbZ1 {
      max-height: 380px; } }

.room__reservations__mobile___-2RVt7jO30E2_3yWtUJ_nP- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .room__reservations__mobile___-2RVt7jO30E2_3yWtUJ_nP- button[class*="cta"] {
    padding: 12px 14px; }
  .room__reservations__mobile___-2RVt7jO30E2_3yWtUJ_nP- p {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-left: 15px; }

.room__form_container___-2N76ZbHLmUmCUXIKfnByL0 {
  padding-top: 60px;
  border-top: 1px solid #000000;
  margin: 100px 0px 0px; }
  @media (min-width: 1180px) {
    .room__form_container___-2N76ZbHLmUmCUXIKfnByL0 {
      padding: 60px 80px 0px; } }

.room__gallery_toggle___-1JFGA9HPekW-hsKMFYHYPr {
  position: absolute;
  top: -70px;
  right: 20px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (min-width: 768px) {
    .room__gallery_toggle___-1JFGA9HPekW-hsKMFYHYPr {
      right: 70px; } }

.room__reservations_form_loader___-3z1BqqowUuKmorARYUQY9_ {
  text-align: center; }
  .room__reservations_form_loader___-3z1BqqowUuKmorARYUQY9_ span {
    position: relative;
    top: -40px;
    font-size: 0.75rem;
    line-height: 0.75rem; }

.room__print_page_container___-26BhODFxKWS9f3xQNmxMaP {
  margin: 0px 0px 0px 20px; }
  .room__print_page_container___-26BhODFxKWS9f3xQNmxMaP button[class*="cta"] {
    padding: 12px 14px; }
