/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.property_detail___-3uN48sLpQ-aEZEdsvGlFNm {
  display: block; }

.property_detail__container___-20Mt4H4rlj7DXNIwSz41Od {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .property_detail__container___-20Mt4H4rlj7DXNIwSz41Od {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .property_detail__container___-20Mt4H4rlj7DXNIwSz41Od {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .property_detail__container___-20Mt4H4rlj7DXNIwSz41Od {
      padding-left: 120px;
      padding-right: 120px; } }

.property_detail__content___-3koKJSTqj-HpuRt-Sj13hY {
  margin-top: 60px; }
  .property_detail__content___-3koKJSTqj-HpuRt-Sj13hY strong {
    font-family: "Akkurat", sans-serif;
    font-weight: bold; }
  .property_detail__content___-3koKJSTqj-HpuRt-Sj13hY ul {
    padding-left: 20px; }
    .property_detail__content___-3koKJSTqj-HpuRt-Sj13hY ul li {
      list-style: disc; }

.property_detail__media___-3SCBnCM72CYBuAQKW-SXNi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40vw;
  max-height: 530px; }

.property_detail__media__image___-QEELinkKeqTvH3_M0e1sr {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  margin-right: 2.5%; }
  .property_detail__media__image___-QEELinkKeqTvH3_M0e1sr:nth-child(2) {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
    margin-right: 0px; }
  .property_detail__media__image___-QEELinkKeqTvH3_M0e1sr:only-of-type {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-right: 0px; }

div.property_detail__media__video___-2EXwhAtswXpNl35x6VoUyD {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 75%;
          flex: 1 1 75%;
  background: black; }
  @media (min-width: 600px) {
    div.property_detail__media__video___-2EXwhAtswXpNl35x6VoUyD > div {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    div.property_detail__media__video___-2EXwhAtswXpNl35x6VoUyD video {
      width: auto !important;
      height: 420px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); } }
  @media (min-width: 600px) and (min-width: 768px) {
    div.property_detail__media__video___-2EXwhAtswXpNl35x6VoUyD video {
      min-height: 750px;
      width: 1050px !important;
      height: 100% !important; } }

.property_detail__bottom_images___-2daFlKRwbA2lthz6JFTMTB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40vw;
  max-height: 530px;
  margin: 0px -30px; }
  @media (min-width: 768px) {
    .property_detail__bottom_images___-2daFlKRwbA2lthz6JFTMTB {
      margin: 0px; } }

.property_detail__bottom_images__image___-1T74fZ0b3lw-H5G-ZLIkAz {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%; }
  .property_detail__bottom_images__image___-1T74fZ0b3lw-H5G-ZLIkAz:first-child {
    margin-right: 2.5%; }

.property_detail__other_hotels___-wzc8cJ2YDPMj2lZO-29ri {
  text-align: center;
  background: #e9ece4; }
  .property_detail__other_hotels___-wzc8cJ2YDPMj2lZO-29ri h2 {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 0.4rem); }
    @media (min-width: 1026px) {
      .property_detail__other_hotels___-wzc8cJ2YDPMj2lZO-29ri h2 {
        font-size: 3.75rem;
        line-height: calc(3.75rem + 0.4rem); } }

.property_detail__other_hotels__list___-VQSDYYCL-VViO23kwJ55M {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1026px) {
    .property_detail__other_hotels__list___-VQSDYYCL-VViO23kwJ55M {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

.property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A:hover {
    opacity: 0.7; }
  .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A:nth-child(odd):not(:last-child) {
    margin-right: 4%; }
  .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A a {
    display: block; }
  @media (min-width: 1026px) {
    .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 22%;
              flex: 1 1 22%; }
      .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A:nth-child(odd) {
        margin-right: 0; }
      .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A:not(:last-child) {
        margin-right: 2.7%; } }
  .property_detail__other_hotels__list__item___-PRiftARtmqg3tRMOzkW8A h3 {
    margin-top: 15px;
    font-family: "Akkurat", sans-serif;
    font-size: 1.0625rem;
    line-height: 1.0625rem; }

.property_detail__other_hotels__list__item__image___-rmBTbR_a8m3pYt5CnYdWd {
  height: 30vw; }
  @media (min-width: 1026px) {
    .property_detail__other_hotels__list__item__image___-rmBTbR_a8m3pYt5CnYdWd {
      max-height: 380px; } }
