/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.tabs___-1lvkH4qx72oj4RIrxvD3a0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-overflow-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1026px) {
    .tabs___-1lvkH4qx72oj4RIrxvD3a0 {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

.tabs__item___-1hArCZ0wlH31OBHTOjVgrr {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  padding: 10px 18px;
  background: #f2f2f0;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  white-space: pre;
  margin: 0px 10px 20px 0px;
  text-align: center;
  position: relative;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer; }
  .tabs__item___-1hArCZ0wlH31OBHTOjVgrr.active___-71Fiyjq4U-c5SSL6Cn997, .tabs__item___-1hArCZ0wlH31OBHTOjVgrr:hover {
    background: #181818;
    color: #ffffff; }
    .tabs__item___-1hArCZ0wlH31OBHTOjVgrr.active___-71Fiyjq4U-c5SSL6Cn997:after, .tabs__item___-1hArCZ0wlH31OBHTOjVgrr:hover:after {
      opacity: 1; }
  .tabs__item___-1hArCZ0wlH31OBHTOjVgrr:after {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    content: "";
    opacity: 0;
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid #181818; }

html[class*="accessible"] .tabs__item___-1hArCZ0wlH31OBHTOjVgrr.active___-71Fiyjq4U-c5SSL6Cn997,
html[class*="accessible"] .tabs__item___-1hArCZ0wlH31OBHTOjVgrr:hover {
  color: #ffffff !important; }

html[class*="accessible"] .tabs__item___-1hArCZ0wlH31OBHTOjVgrr {
  border: 1px solid #000000; }
