/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .booking_widget___-3NBDQZQFF6SbM9vTitI2Bm {
  background: white;
  display: none;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .booking_widget___-3NBDQZQFF6SbM9vTitI2Bm {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  html:not([class*="accessible"]) .booking_widget___-3NBDQZQFF6SbM9vTitI2Bm > div:first-child, html:not([class*="accessible"]) .booking_widget___-3NBDQZQFF6SbM9vTitI2Bm > div:nth-child(2) {
    border-right: 1px solid #e2e2e2; }

html:not([class*="accessible"]) .booking_widget__lodging___-1SuVj6kBIw2K1NMN9G3I-c {
  margin: -10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: left; }
  html:not([class*="accessible"]) .booking_widget__lodging___-1SuVj6kBIw2K1NMN9G3I-c.error___-H_EKBPH3l2I6KHZUAe-c- {
    border: 1px solid #af0e0e !important; }

html:not([class*="accessible"]) .booking_widget__dates___-1kt51P49ARX0rUTSHauP1y {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 270px;
          flex: 0 0 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 10px;
  height: 46px; }
  html:not([class*="accessible"]) .booking_widget__dates___-1kt51P49ARX0rUTSHauP1y.error___-H_EKBPH3l2I6KHZUAe-c- {
    border: 1px solid #af0e0e; }
  html:not([class*="accessible"]) .booking_widget__dates___-1kt51P49ARX0rUTSHauP1y input {
    border: none; }

html:not([class*="accessible"]) .booking_widget__guests___-37ziagxlK3NHf0ogngBi02 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 135px;
          flex: 0 0 135px; }
  html:not([class*="accessible"]) .booking_widget__guests___-37ziagxlK3NHf0ogngBi02.error___-H_EKBPH3l2I6KHZUAe-c- {
    border: 1px solid #af0e0e; }

html:not([class*="accessible"]) .booking_widget__submit___-39fM94AjLTvEpvHy7YcduF {
  margin-left: 20px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none; }

html:not([class*="accessible"]) .booking_widget__date_picker___-2He4glJwN_-syo5_9Dj1UB {
  position: relative; }

html:not([class*="accessible"]) .booking_widget__dates_separator___-KYdG1ziRy-MmYegpr-VbF {
  color: #7f7f7f;
  font-size: 0.875rem;
  line-height: 0.875rem; }

html[class*="accessible"] .booking_widget___-3NBDQZQFF6SbM9vTitI2Bm {
  max-width: 800px;
  margin: 0px auto 50px;
  padding-bottom: 50px; }
