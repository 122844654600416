/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
@-webkit-keyframes loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }
@keyframes loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

.loader___-2G1V6FR9XO0tfWD4ZaIZtj {
  padding: 50px; }

.loader__inner___-vWB5IH3dWptvD0OOcEQVV {
  text-align: center; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f6891f;
  -webkit-animation: loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG 1.2s linear infinite;
          animation: loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG 1.2s linear infinite; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(1) {
  top: 8px;
  left: 8px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(2) {
  top: 8px;
  left: 32px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(3) {
  top: 8px;
  left: 56px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(4) {
  top: 32px;
  left: 8px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(5) {
  top: 32px;
  left: 32px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(6) {
  top: 32px;
  left: 56px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(7) {
  top: 56px;
  left: 8px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(8) {
  top: 56px;
  left: 32px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.loader__lds_grid___-3A5DZ7jHFzD_GkiN9GWYLG div:nth-child(9) {
  top: 56px;
  left: 56px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s; }
