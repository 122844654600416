/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.results_block___-K3_ZIUGn3VXe5do834GOw {
  display: block; }

@media (min-width: 1026px) {
  .results_block__town_map___-36_3SclDxAUQ9HbtZqmA_k {
    margin-top: -50px; } }

.results_block__options___-e7_sWGCM-MajIs4dzdwK6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-overflow-style: none;
  margin: 50px 0px 10px; }
  @media (min-width: 1026px) {
    .results_block__options___-e7_sWGCM-MajIs4dzdwK6 {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      margin: 100px 0px 10px; } }

.results_block__map_modal___-mm_tJUjD0JGvVcTVxfvFy button[class*="hamburger"] div[class*="line"] {
  background-color: #000000; }

.results_block__map_view___-2bnFlivYGXJAXEKC2y-bqR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 1026px) {
    .results_block__map_view___-2bnFlivYGXJAXEKC2y-bqR {
      height: 800px; } }

.results_block__map_view__map___-3oeHJUhiY5AtyVex43Uz4u {
  display: none;
  position: relative;
  z-index: 0; }
  @media (min-width: 1026px) {
    .results_block__map_view__map___-3oeHJUhiY5AtyVex43Uz4u {
      display: block;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 55%;
              flex: 1 1 55%; } }

.results_block__map_view__list___-ULVG0inCgLO9dyAODIXyz {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 45%;
          flex: 1 1 45%;
  -ms-overflow-style: none; }
  @media (min-width: 1026px) {
    .results_block__map_view__list___-ULVG0inCgLO9dyAODIXyz {
      margin-right: 2%;
      max-height: 1000px;
      overflow: scroll;
      position: relative;
      padding: 0px 5px 0px 0px; } }
  .results_block__map_view__list___-ULVG0inCgLO9dyAODIXyz::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  .results_block__map_view__list___-ULVG0inCgLO9dyAODIXyz::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.3); }

.results_block__list_view___-3c8fCA8v57paS7OVSf92b- {
  display: block; }

.results_block__list_view__list__item___-3Ti7iK-eIcWpaiblIy13LC,
.results_block__map_view__list__item___-1c-g-h19e0gFl7fkl6l5u {
  margin-bottom: 20px; }

.results_block__options__tabs___-3a0JBkQIgbwnC8mbkgSAO7 {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px; }
  @media (min-width: 1026px) {
    .results_block__options__tabs___-3a0JBkQIgbwnC8mbkgSAO7 {
      margin-bottom: 10px;
      width: 60%; } }

.results_block__options__filters___-3RTdA0M3rLYFTQenmg4yDQ {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%; }

.results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce {
  white-space: pre;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  cursor: pointer;
  position: relative; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce path,
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce label {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce:hover .results_block__options__filters__item__label___-2AV0xS1-LV60TIfi2x7Wlb path {
    fill: #f6891f; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce:hover .results_block__options__filters__item__label___-2AV0xS1-LV60TIfi2x7Wlb label {
    color: #f6891f; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce:not(:last-child) {
    margin-right: 25px; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce div svg {
    height: 25px;
    width: 25px;
    margin-right: 5px; }
  .results_block__options__filters__item___-2-x_6YJz73B-7DAiWFIjce div label {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-transform: uppercase; }

.results_block__options__filters__item__label___-2AV0xS1-LV60TIfi2x7Wlb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.results_block__price___-dJ9PJyAN6QbzqkEuuoes2,
.results_block__beds___-JwMFy-zIt5sdPJ1S2j7XI,
.results_block__more_filters___-kH0hJAr_OvBr4DlZf_Y9R {
  position: absolute;
  bottom: -20px;
  left: 52%;
  z-index: 1;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
  background: #f2f2f0;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 2px 1px #e2e2e2;
          box-shadow: 0px 0px 2px 1px #e2e2e2;
  text-align: right; }
  .results_block__price___-dJ9PJyAN6QbzqkEuuoes2:after,
  .results_block__beds___-JwMFy-zIt5sdPJ1S2j7XI:after,
  .results_block__more_filters___-kH0hJAr_OvBr4DlZf_Y9R:after {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    content: "";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #f2f2f0; }

.results_block__more_filters___-kH0hJAr_OvBr4DlZf_Y9R {
  right: 0px !important;
  left: inherit;
  -webkit-transform: translate(0%, 100%) !important;
          transform: translate(0%, 100%) !important;
  width: 450px;
  text-align: left; }
  .results_block__more_filters___-kH0hJAr_OvBr4DlZf_Y9R:after {
    right: 0px !important;
    left: inherit;
    -webkit-transform: translate(-100%, -100%) !important;
            transform: translate(-100%, -100%) !important; }

.results_block__price__inner___-1IISzL58D7nUUpM-amiyGv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }

.results_block__price__field___-2ZSJvD-pqV4r6PXpmpxGih:first-child {
  margin-right: 20px; }

.results_block__price__field___-2ZSJvD-pqV4r6PXpmpxGih label {
  display: block; }

.results_block__beds__field___-3XpIKmJqwbmuQGEGqrk9FB {
  margin-bottom: 20px; }
  .results_block__beds__field___-3XpIKmJqwbmuQGEGqrk9FB input {
    display: block; }

.results_block__more_filters__field___-3BThnjsEBhWci-rDTZSJrK {
  margin-bottom: 20px; }
  .results_block__more_filters__field___-3BThnjsEBhWci-rDTZSJrK input {
    display: block; }

.results_block__more_filters__inner___-YOmxhSsLkOm-ZVGlXu_V3 li > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .results_block__more_filters__inner___-YOmxhSsLkOm-ZVGlXu_V3 li > div > div {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%; }

.results_block__map_view_mobile___-i2nW5sQpxs1743N5vd_Dq {
  display: block; }
  @media (min-width: 768px) {
    .results_block__map_view_mobile___-i2nW5sQpxs1743N5vd_Dq {
      margin: -25px -25px 0px; } }
  @media (min-width: 768px) {
    .results_block__map_view_mobile___-i2nW5sQpxs1743N5vd_Dq {
      margin: 0px; } }

.results_block__map_view_mobile__map___-LZJz8WKbWvzUIfGGxPrK1 {
  height: 50vh;
  margin: -25px -25px 50px; }

.results_block__map_view_mobile__list___-Otp7ayAzjirV1Huxb3N5G {
  overflow: auto;
  width: 100%;
  white-space: nowrap; }

.results_block__map_view_mobile__list__item___-1UrVES_lb6BA3Wb08Me7Od {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%; }
  .results_block__map_view_mobile__list__item___-1UrVES_lb6BA3Wb08Me7Od:not(:last-child) {
    margin-right: 20px; }

.results_block__map_toggle___-3zoUu6dRxUPDWrzj506h8F {
  display: block;
  position: fixed;
  background: black;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  cursor: pointer; }
  .results_block__map_toggle___-3zoUu6dRxUPDWrzj506h8F:hover {
    opacity: 0.7; }
  .results_block__map_toggle___-3zoUu6dRxUPDWrzj506h8F svg {
    height: 27px;
    width: 27px; }
    .results_block__map_toggle___-3zoUu6dRxUPDWrzj506h8F svg path {
      fill: #ffffff; }
  @media (min-width: 1026px) {
    .results_block__map_toggle___-3zoUu6dRxUPDWrzj506h8F {
      display: none; } }
