.invalidBrowser___-2amAfVmRJZqs7RMYPLHlow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: arial, sans-serif;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  top: 20px; }
  .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .content___-NfpFXb4or55CfmJS2jYO0 {
    backface-visibility: hidden;
    border: 4px solid #39b54a;
    max-height: calc(100vh - 40px);
    max-width: 700px;
    overflow: scroll;
    padding: 50px;
    width: 100%; }
  .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .images___-1Se2nMZVggfLgw-d1w4OMB,
  .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd {
    display: inline-block;
    vertical-align: middle;
    width: 50%; }
    @media (max-width: 768px) {
      .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .images___-1Se2nMZVggfLgw-d1w4OMB,
      .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd {
        width: 100%; } }
  .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .images___-1Se2nMZVggfLgw-d1w4OMB {
    padding-right: 20px; }
    @media (max-width: 768px) {
      .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .images___-1Se2nMZVggfLgw-d1w4OMB {
        padding-right: 0;
        text-align: center; } }
    .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .images___-1Se2nMZVggfLgw-d1w4OMB img {
      display: inline-block;
      max-width: 50%;
      padding: 10px; }
  .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd {
    padding-left: 20px; }
    @media (max-width: 768px) {
      .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd {
        margin-top: 20px;
        padding-left: 0; } }
    .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd a {
      text-decoration: underline; }
    .invalidBrowser___-2amAfVmRJZqs7RMYPLHlow .text___-1GJzbAGtvHHrpoMvjy_gwd p {
      font-size: 16px;
      line-height: 24px;
      margin: 0; }
