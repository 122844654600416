/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.booking_modal___-2gCLZCoYvAroHK2tnVLKqb {
  background: #f2f2f0;
  padding: 70px 0px 50px;
  position: relative;
  overflow-y: scroll; }
  @media (min-width: 1026px) {
    .booking_modal___-2gCLZCoYvAroHK2tnVLKqb {
      padding: 80px 35px 50px; } }
  @media (min-width: 1300px) {
    .booking_modal___-2gCLZCoYvAroHK2tnVLKqb {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0px; } }

div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
  background-color: #f2f2f0;
  padding: 0px; }
  div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB div[class*="modal__close"] {
    top: 20px;
    right: 20px; }
  @media (min-width: 1026px) {
    div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
      padding: 80px;
      background-color: rgba(0, 0, 0, 0.6); } }
  @media (min-width: 1180px) {
    div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
      padding: 80px 150px; } }
  @media (min-width: 1300px) {
    div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
      padding: 100px 50px 120px; } }
  @media (min-width: 1400px) {
    div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
      padding: 100px 100px 120px; } }
  @media (min-width: 1026px) {
    div.booking_modal__wrapper___-2JbWSCoXKpizZd_IJhY1bB {
      overflow: hidden; } }

.booking_modal__calendars___-7PrXu6GhqKY_a4my4O- {
  position: relative;
  margin-bottom: 30px;
  padding-top: 50px; }
  @media (min-width: 768px) {
    .booking_modal__calendars___-7PrXu6GhqKY_a4my4O- {
      padding-top: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      overflow: auto; } }
  @media (min-width: 1300px) {
    .booking_modal__calendars___-7PrXu6GhqKY_a4my4O- {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 70%;
              flex: 1 1 70%;
      margin-right: 50px;
      margin-bottom: 0px;
      padding-top: 0px; } }

.booking_modal__inputs___-x5yT4il-K7gmBEND0b68X {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 30px;
  padding: 0px 35px; }
  .booking_modal__inputs___-x5yT4il-K7gmBEND0b68X h3 {
    margin-bottom: 10px; }
  @media (min-width: 1026px) {
    .booking_modal__inputs___-x5yT4il-K7gmBEND0b68X {
      padding: 0px;
      margin-top: 0px; } }

.booking_modal__inputs__flex___-au85ZmqcYFVyCp4Q09Z2R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0px; }
  .booking_modal__inputs__flex___-au85ZmqcYFVyCp4Q09Z2R > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  @media (min-width: 1300px) {
    .booking_modal__inputs__flex___-au85ZmqcYFVyCp4Q09Z2R {
      margin: 0px; } }

.booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU,
.booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px; }
  .booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU button,
  .booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 button {
    display: block;
    position: absolute;
    top: 59%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1;
    display: block;
    background: white;
    border: 2px solid #e2e2e2;
    padding: 23px;
    border-radius: 100%; }
    .booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU button:before,
    .booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 button:before {
      width: 15px;
      height: 15px;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 2px solid #e2e2e2;
      border-top: 2px solid #e2e2e2; }
  @media (min-width: 768px) {
    .booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU,
    .booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50px;
              flex: 0 0 50px;
      width: 50px; } }

.booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU {
  position: absolute;
  top: 0px;
  left: 40%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }
  @media (min-width: 768px) {
    .booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU {
      position: relative;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      left: 20px;
      margin-right: 10px; } }
  .booking_modal__calendars__previous___-P9MbWcL5lUgtjcXa4najU button:before {
    left: 40%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 {
  position: absolute;
  top: 0px;
  right: 40%;
  -webkit-transform: translate(50%, 0%);
          transform: translate(50%, 0%); }
  @media (min-width: 768px) {
    .booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 {
      position: relative;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      right: 20px;
      margin-left: 10px; } }
  .booking_modal__calendars__next___-sbdCHte30CdufPx9MeeT3 button:before {
    right: 40%;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }

.booking_modal__calendars__inner___-1en45d1oqTR351ENjqrz4x {
  position: relative;
  z-index: 0;
  max-width: 300px;
  margin: 0px auto; }
  .booking_modal__calendars__inner___-1en45d1oqTR351ENjqrz4x label {
    text-transform: uppercase;
    color: #7f7f7f;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    display: block;
    text-align: left; }
  @media (min-width: 1026px) {
    .booking_modal__calendars__inner___-1en45d1oqTR351ENjqrz4x {
      max-width: none; }
      .booking_modal__calendars__inner___-1en45d1oqTR351ENjqrz4x:nth-child(2) {
        margin-right: 50px; } }

.booking_modal__select___-1xbjrR2lB-X85I7Yhls3w5 label {
  text-transform: uppercase;
  color: #7f7f7f;
  font-family: "Akkurat", sans-serif;
  font-weight: bold; }

.booking_modal__select___-1xbjrR2lB-X85I7Yhls3w5:last-child {
  margin-left: 20px; }

.booking_modal__select___-1xbjrR2lB-X85I7Yhls3w5.error___-1zUnx6Gh_prQxRvQulX4zC div[class~="booking_modal_select"] {
  border: 1px solid #af0e0e !important; }
