/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
@media (min-width: 1026px) {
  html:not([class*="accessible"]) .villa_carousel___-bbxqHAKyzKY98cNXVC53c {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; } }

@media (min-width: 1026px) {
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 0px 10px; } }

html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-arrow"] {
  display: block;
  background: white;
  border: 1px solid #7f7f7f;
  padding: 15px;
  border-radius: 100%;
  top: 25vw; }
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-arrow"]:before {
    width: 10px;
    height: 10px;
    border-color: #7f7f7f; }

html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-next"] {
  right: -10px; }
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-next"]:before {
    right: 12px; }

html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-prev"] {
  left: -10px; }
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-prev"]:before {
    left: 12px; }

@media (min-width: 1026px) {
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-arrow"] {
    top: 21vw;
    padding: 25px; }
    html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-arrow"]:before {
      width: 20px;
      height: 20px;
      border-color: #7f7f7f; }
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-next"] {
    right: 150px; }
    html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-next"]:before {
      right: 20px; }
  html:not([class*="accessible"]) .villa_carousel__inner___-EX7b3_2AB99JePuR-bchE button[class~="slick-prev"]:before {
    left: 20px; } }

html:not([class*="accessible"]) .villa_carousel__title___-2ajoZClZfZOT1USC8p--dK {
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  color: #7f7f7f;
  margin-bottom: 25px; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .villa_carousel__title___-2ajoZClZfZOT1USC8p--dK {
      margin-bottom: 15px;
      text-align: left; } }

html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
  display: none;
  padding-left: 20px; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
      margin-right: 5%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media (min-width: 1180px) {
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
      width: 30%; } }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
      padding-left: 50px; } }
  @media (min-width: 1300px) {
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf {
      padding-left: 120px; } }
  html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf > div {
    position: relative;
    text-align: left; }
    html:not([class*="accessible"]) .villa_carousel__content_container___-uyYGBdD7NeAhv8C2a9tNf > div p {
      margin-bottom: 40px; }

html:not([class*="accessible"]) .villa_carousel__mobile_content___--17ME1vI81ZUfepXEExVH p {
  margin-bottom: 30px; }

html:not([class*="accessible"]) .villa_carousel__slick_container___-1y2KN7ehZpBkA3Chjm4m1Q {
  padding: 0px 20px; }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .villa_carousel__slick_container___-1y2KN7ehZpBkA3Chjm4m1Q {
      padding: 0px 30px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .villa_carousel__slick_container___-1y2KN7ehZpBkA3Chjm4m1Q {
      padding: 0px 50px; } }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .villa_carousel__slick_container___-1y2KN7ehZpBkA3Chjm4m1Q {
      padding: 0px;
      width: 64%;
      margin-right: -5vw; } }
  @media (min-width: 1180px) {
    html:not([class*="accessible"]) .villa_carousel__slick_container___-1y2KN7ehZpBkA3Chjm4m1Q {
      width: 70%; } }

html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ {
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  position: relative; }
  html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ h3 {
    font-family: "Baskerville", serif;
    margin-top: 30px;
    font-size: 2.5rem;
    line-height: 2.5rem;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ {
      height: 45vw; }
      html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ > div {
        -webkit-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ.active___-2pjcKkMEV4zBrq7BgrMWDS h3 {
        opacity: 0; }
      html:not([class*="accessible"]) .villa_carousel__item___-2JdDbdma2AssnUBQ0n89WZ h3 {
        font-family: "Akkurat", sans-serif;
        text-align: center;
        font-size: 1rem;
        line-height: 1rem;
        width: 75%;
        margin: 15px auto 0px; } }

html:not([class*="accessible"]) .villa_carousel__item__image___-15dbtlTHJtgHCIt3wUaK61 {
  height: 50vw; }
  html:not([class*="accessible"]) .villa_carousel__item__image___-15dbtlTHJtgHCIt3wUaK61 div[class*="image"] {
    -webkit-transition: -webkit-transform 250ms ease-in-out;
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out; }
  @media (min-width: 1026px) {
    html:not([class*="accessible"]) .villa_carousel__item__image___-15dbtlTHJtgHCIt3wUaK61 {
      height: 27vw;
      width: 75%;
      margin: 0px auto; }
      html:not([class*="accessible"]) .villa_carousel__item__image___-15dbtlTHJtgHCIt3wUaK61.active___-2pjcKkMEV4zBrq7BgrMWDS div[class*="image"] {
        -webkit-transform: scale(1.25);
                transform: scale(1.25); } }
