/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.experience_detail___-1jv0q9CIaUK848TEFVfxXm {
  display: block; }

.experience_detail__container___-pA_zGLyzHAVYl00ws7j6- {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .experience_detail__container___-pA_zGLyzHAVYl00ws7j6- {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .experience_detail__container___-pA_zGLyzHAVYl00ws7j6- {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .experience_detail__container___-pA_zGLyzHAVYl00ws7j6- {
      padding-left: 120px;
      padding-right: 120px; } }

@media (min-width: 1180px) {
  .experience_detail__form_container___-9ajsVvK6tLhAvtf21j8mL {
    padding: 0px 80px; } }
