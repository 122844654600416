/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.contact___-1j_fQvhgcjRF10fgOtx0kK {
  display: block; }

.contact__container___-1E0NNgjFtY4wYEiQV2Xbn_ {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .contact__container___-1E0NNgjFtY4wYEiQV2Xbn_ {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .contact__container___-1E0NNgjFtY4wYEiQV2Xbn_ {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .contact__container___-1E0NNgjFtY4wYEiQV2Xbn_ {
      padding-left: 120px;
      padding-right: 120px; } }

@media (min-width: 1180px) {
  .contact__form_container___-RAB0vopdQkPL1Gpgc1uqj {
    padding: 0px 150px; } }
