/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.partnership___-3e_-zPpZ-Cal-qkPASTpz0 {
  display: block; }

.partnership__container___-3gVovibf6n4fZLV-4HwDTc {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .partnership__container___-3gVovibf6n4fZLV-4HwDTc {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .partnership__container___-3gVovibf6n4fZLV-4HwDTc {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .partnership__container___-3gVovibf6n4fZLV-4HwDTc {
      padding-left: 120px;
      padding-right: 120px; } }

@media (min-width: 1180px) {
  .partnership__form_container___-3-UvaGsl_QE10WCeTqDrh2 {
    padding: 0px 80px 0px;
    margin: 0px 70px; } }

.partnership__contact_phone___-wGnK2igZxb9tce_CexKeJ {
  margin: 20px auto;
  text-align: center; }
