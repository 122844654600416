/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .image_grid___-1UGUOuQjLtViJHZ60IQmBk {
  position: relative;
  margin: 30px 0px; }
  html:not([class*="accessible"]) .image_grid___-1UGUOuQjLtViJHZ60IQmBk h3 {
    color: white;
    text-transform: uppercase;
    z-index: 1;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 1.25rem;
    line-height: 1.5rem; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .image_grid___-1UGUOuQjLtViJHZ60IQmBk {
      max-height: 750px;
      height: 70vw; }
      html:not([class*="accessible"]) .image_grid___-1UGUOuQjLtViJHZ60IQmBk.static___-5RxG8PJkKJ--iCovG3bdZ {
        max-height: none;
        height: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }

html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4 {
  display: block;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  height: 50vw;
  position: relative; }
  html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4:hover {
    opacity: 0.7; }
  html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4 a {
    height: 100%;
    width: 100%;
    display: block; }
  html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4:not(:last-child) {
    margin-bottom: 30px; }
  html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4 {
      height: auto; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4:not(:last-child) {
        margin-bottom: 0px; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.static___-5RxG8PJkKJ--iCovG3bdZ {
        height: 39vw;
        max-height: 475px; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.static___-5RxG8PJkKJ--iCovG3bdZ:nth-child(1):not(:only-child) {
          margin-right: 2.5%; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.static___-5RxG8PJkKJ--iCovG3bdZ:nth-child(1), html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.static___-5RxG8PJkKJ--iCovG3bdZ:nth-child(2) {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 48.75%;
                  flex: 1 1 48.75%; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.static___-5RxG8PJkKJ--iCovG3bdZ:nth-child(3) {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
                  flex: 1 1 100%;
          margin-top: 2.5%; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.dynamic___-1jzNrx1bO987vAZXGu4gHO {
        position: absolute; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(1) {
          left: 0px;
          top: 0px;
          width: 49%;
          height: 100%; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(2) {
          right: 0px;
          top: 0px;
          width: 49%;
          height: 63.5%; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(3) {
          left: 51%;
          bottom: 0%;
          width: 23.5%;
          height: 33.5%; }
        html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(4) {
          right: 0%;
          bottom: 0%;
          width: 23.5%;
          height: 33.5%; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.reversed___-dapO5rWWFPhulO4Y1KxrX.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(1) {
        left: 0px;
        top: 0px;
        width: 49%;
        height: 63.5%; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.reversed___-dapO5rWWFPhulO4Y1KxrX.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(2) {
        top: inherit;
        left: 0%;
        bottom: 0%;
        width: 23.5%;
        height: 33.5%; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.reversed___-dapO5rWWFPhulO4Y1KxrX.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(3) {
        left: 25.5%;
        bottom: 0%;
        width: 23.5%;
        height: 33.5%; }
      html:not([class*="accessible"]) .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4.reversed___-dapO5rWWFPhulO4Y1KxrX.dynamic___-1jzNrx1bO987vAZXGu4gHO:nth-child(4) {
        right: 0px;
        top: 0px;
        width: 49%;
        height: 100%; } }

html[class*="accessible"] .image_grid__image___-1yo4ItNtAdtWyI168vJ0o4 {
  height: auto; }
