/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.quick_booking___-2nOLq476FFPCS8eVvKlhQo {
  position: relative;
  margin-top: 60px;
  padding: 0px 20px; }
  .quick_booking___-2nOLq476FFPCS8eVvKlhQo h2 {
    font-size: 1.5rem;
    line-height: 1.5rem; }
  .quick_booking___-2nOLq476FFPCS8eVvKlhQo h3,
  .quick_booking___-2nOLq476FFPCS8eVvKlhQo label {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #7f7f7f;
    font-size: 1rem;
    line-height: 1rem; }
  @media (min-width: 1026px) {
    .quick_booking___-2nOLq476FFPCS8eVvKlhQo {
      background: #f2f2f0;
      border: 1px solid #e2e2e2;
      padding: 25px;
      margin: 0px;
      overflow: visible; } }

.quick_booking__lodging___-EzbCDGRFGRtUU5LIKTEDo {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: left; }

.quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb div[class*="DayPickerInput"] input {
    height: 44px;
    border: none;
    text-align: center;
    padding: 0px; }
  .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
    .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb > div:nth-child(2) {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 20px;
              flex: 0 0 20px;
      height: 20px;
      margin: 0px 15px; }
      .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb > div:nth-child(2) svg {
        height: 100%;
        width: 100%; }
        .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb > div:nth-child(2) svg path {
          fill: #7f7f7f; }
    .quick_booking__dates___-bTUPowVnTn8ydT24o9Xsb > div:nth-child(3) div[class*="DayPicker"] {
      right: 0px;
      left: 0px; }

.quick_booking__lodging_details___-2kijQvL_K7Zwpc96c6A7rd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px; }

.quick_booking__guests___-3BJx_EUqy_PISRU6nB9nq0 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  margin-right: 20px; }
  .quick_booking__guests___-3BJx_EUqy_PISRU6nB9nq0.error___-16ubvhk63R34CHwtBY0PKt {
    border: 1px solid #af0e0e !important; }

.quick_booking__rooms___-2ZSymdwTSbaMaX4icuo808 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .quick_booking__rooms___-2ZSymdwTSbaMaX4icuo808 p {
    font-size: 1rem;
    line-height: 1rem;
    font-family: "Akkurat", sans-serif;
    padding-left: 10px; }
  .quick_booking__rooms___-2ZSymdwTSbaMaX4icuo808.error___-16ubvhk63R34CHwtBY0PKt {
    border: 1px solid #af0e0e !important; }

.quick_booking__date_picker___-i8lINTB9YdY8NdrjCEVRm.error___-16ubvhk63R34CHwtBY0PKt input {
  border: 1px solid #af0e0e !important; }

.quick_booking__date_picker___-i8lINTB9YdY8NdrjCEVRm input:focus {
  background: #f6891f;
  color: #ffffff; }

.quick_booking__total___-2Z0ap4agQDsMuyr0mhrt52 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px; }
  .quick_booking__total___-2Z0ap4agQDsMuyr0mhrt52 p {
    font-family: "Akkurat", sans-serif;
    font-weight: bold; }
    .quick_booking__total___-2Z0ap4agQDsMuyr0mhrt52 p:first-child {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }

.quick_booking__fees___-a0dSfUpd5Yu8Ej9lwzEb9 {
  margin-bottom: 10px; }

.quick_booking__fees__row___-32z0kKXBsFgupUYASfrsQr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .quick_booking__fees__row___-32z0kKXBsFgupUYASfrsQr p:first-child {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.quick_booking__loading___-Pl95GaaB6k6gU4Zfgj8vG {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2; }
  .quick_booking__loading___-Pl95GaaB6k6gU4Zfgj8vG p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    color: #ffffff; }
  .quick_booking__loading___-Pl95GaaB6k6gU4Zfgj8vG.visible___-1IP-73z2xqEwFMQ7fYHgvj {
    pointer-events: all;
    opacity: 1; }

.quick_booking__promo___-3QGj8iE3dw--tMO34Oc08g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px; }
  .quick_booking__promo___-3QGj8iE3dw--tMO34Oc08g > input {
    height: 44px;
    border: 1px solid #e2e2e2;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-right: 25px;
    display: block; }
  .quick_booking__promo___-3QGj8iE3dw--tMO34Oc08g > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .quick_booking__promo___-3QGj8iE3dw--tMO34Oc08g > div > span {
      color: #f6891f;
      font-family: "Akkurat", sans-serif;
      font-weight: bold; }

.quick_booking__actions___-34KSWHvAwF9CW_HDwJF8qa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .quick_booking__actions___-34KSWHvAwF9CW_HDwJF8qa button {
    width: 100%;
    text-align: center;
    display: block; }

.nodeparture___-1RhUta1dC9MpdDKxki_xKe {
  background: black; }

.quick_booking__no_availability___-16ow9t6GA93S3aO2IjPG54 {
  margin: 10px 0px 20px; }

.quick_booking__availability_error___-2rPwQA9D4HYo1tMu0peOid {
  color: #af0e0e;
  margin: 10px 0px 20px; }

.quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j p {
    color: inherit; }
  .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j p:first-child {
    font-size: 1rem;
    line-height: 1rem; }
  .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j p:nth-child(2) {
    margin-top: 3px;
    font-size: 0.625rem;
    line-height: 0.625rem; }
  .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j div.failure___-26C-8OfvhJYK2O2rkWlDqr {
    position: absolute; }
    .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j div.failure___-26C-8OfvhJYK2O2rkWlDqr.NoAvailableInventory___-CaRBria9uKeI4vK_7PKie, .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j div.failure___-26C-8OfvhJYK2O2rkWlDqr.Closed___-2H6C5yCVBd1Q1ZQ5Kk0c9K {
      top: 0;
      left: 50%;
      font-size: 20px;
      -webkit-transform: translate(-50%) rotate(45deg);
              transform: translate(-50%) rotate(45deg);
      width: 2px;
      height: 100%;
      background-color: #af0e0e;
      -webkit-transform-origin: center;
              transform-origin: center; }
  .quick_booking__date_picker__day___-2p7Wj_V0RBGIg4piEQyV8j .tool_tip___-2oCw1M-Av1YcNi-nc-OKdT {
    position: absolute;
    bottom: 0px;
    -webkit-transform: translate(0, 90%);
            transform: translate(0, 90%);
    background: #f6891f;
    color: #ffffff;
    z-index: 1;
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 2px; }
