/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.booking_results___-3zLDFSOK837T0skubaanjX {
  display: block; }
  .booking_results___-3zLDFSOK837T0skubaanjX h1 {
    text-align: center; }

.booking_results__container___-f8DBsR7_rsxlULI15A-2s {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 40px; }
  @media (min-width: 480px) {
    .booking_results__container___-f8DBsR7_rsxlULI15A-2s {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .booking_results__container___-f8DBsR7_rsxlULI15A-2s {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .booking_results__container___-f8DBsR7_rsxlULI15A-2s {
      padding-left: 120px;
      padding-right: 120px; } }
  @media (min-width: 1026px) {
    .booking_results__container___-f8DBsR7_rsxlULI15A-2s {
      padding-top: 60px;
      padding-bottom: 60px; } }

.booking_results__widget___-2x4gyo40K3t4iBVlzSV5dy {
  background: #f2f2f0;
  border: 1px solid #e2e2e2;
  padding: 30px 20px 15px; }
  @media (min-width: 1026px) {
    .booking_results__widget___-2x4gyo40K3t4iBVlzSV5dy {
      padding: 80px 50px; } }

.booking_results__widget__container___-14QlChuehyQgjHbktsVKPZ {
  max-width: 1100px;
  margin: 0px auto; }

.booking_results__error___-1NTKZ6T70Ol3t56NJTMPcj p {
  text-align: center;
  padding: 100px 0px;
  font-size: 20px; }
