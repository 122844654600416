/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.search_results___-10uApPNfsT66FDwxUwTik5 {
  display: block; }
  .search_results___-10uApPNfsT66FDwxUwTik5 h1 {
    text-align: center; }
  .search_results___-10uApPNfsT66FDwxUwTik5 form[class*="search_form"] {
    height: 80px; }

.search_results__header___-1zBuWtM0NBum8b2F1OMen5 {
  background: #f2f2f0;
  border-bottom: 1px solid #e2e2e2; }

@media (min-width: 1026px) {
  .search_results__header__inner___-27e3Q0PM8yylvA99tVSJmZ {
    padding: 0px 120px; } }

.search_results__container___-Iz1wN6KudJFonC2SJmUo5 {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .search_results__container___-Iz1wN6KudJFonC2SJmUo5 {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .search_results__container___-Iz1wN6KudJFonC2SJmUo5 {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .search_results__container___-Iz1wN6KudJFonC2SJmUo5 {
      padding-left: 120px;
      padding-right: 120px; } }

@media (min-width: 1026px) {
  .search_results__list___-2OGUdEOrWY2ykgW1H-9JI7 {
    padding: 0px 80px; } }

.search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP {
  background: #f2f2f0; }
  .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP:not(:last-child) {
    margin-bottom: 20px; }
  .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP h3 {
    margin: 35px 0px 10px; }
  .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP > a {
    padding: 40px 25px;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    display: block;
    width: 100%; }
    .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP > a * {
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out; }
    .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP > a:hover {
      background: #f6891f; }
      .search_results__list__item___-2pLXqcdM0JWwAMaZ0nfNQP > a:hover * {
        color: white !important; }

.search_results__list__item__title___-1VRnbSeMkrh6kVCdX3rWeX {
  font-size: 1.875rem;
  line-height: 1.875rem;
  margin-bottom: 10px; }

.search_results__list__item__path___-1VqLz5yinvpLWznNuwFBT_ {
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #7f7f7f;
  letter-spacing: 1px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-bottom: 20px; }

/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.search_results__form___-3NCD4C_9mOqh662IyAvBS0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  position: relative; }
  @media (min-width: 768px) {
    .search_results__form___-3NCD4C_9mOqh662IyAvBS0 {
      height: 60px; } }

.search_results__form__input___-SMColUnJ129duzoUt0IaM {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 1rem;
  line-height: 1rem;
  padding-left: 60px;
  padding-right: 10px; }
  .search_results__form__input___-SMColUnJ129duzoUt0IaM::-webkit-input-placeholder {
    padding: 0px; }
  .search_results__form__input___-SMColUnJ129duzoUt0IaM::-moz-placeholder {
    padding: 0px; }
  .search_results__form__input___-SMColUnJ129duzoUt0IaM:-ms-input-placeholder {
    padding: 0px; }
  .search_results__form__input___-SMColUnJ129duzoUt0IaM::placeholder {
    padding: 0px; }
  @media (min-width: 768px) {
    .search_results__form__input___-SMColUnJ129duzoUt0IaM {
      font-size: 1.25rem;
      line-height: 1.25rem; } }

.search_results__form__submit___-3gCaiAQz7If95IU_wogcl9 {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  padding: 0px 15px;
  color: #ffffff;
  border: none;
  background: #f6891f;
  margin-left: 5px;
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  @media (min-width: 768px) {
    .search_results__form__submit___-3gCaiAQz7If95IU_wogcl9 {
      font-size: 1.25rem;
      line-height: 1.25rem;
      padding: 0px 30px; } }
  .search_results__form__submit___-3gCaiAQz7If95IU_wogcl9:hover {
    opacity: 0.7; }
  @media all and (-ms-high-contrast: none) {
    .search_results__form__submit___-3gCaiAQz7If95IU_wogcl9 {
      padding: 0px;
      min-width: 80px; } }

.search_results__form__icon___-1AMLGYvPSvVxXdyNTLKtCL {
  position: absolute;
  top: 55%;
  left: 20px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }
  .search_results__form__icon___-1AMLGYvPSvVxXdyNTLKtCL svg {
    width: 25px;
    height: auto; }

.search_results__no_results___-1t9tn7asTEZWgLnUwgtOSj {
  margin: 50px 30px;
  text-align: center; }
  .search_results__no_results___-1t9tn7asTEZWgLnUwgtOSj p {
    font-size: 1.25rem;
    line-height: 1.25rem; }
  @media (min-width: 768px) {
    .search_results__no_results___-1t9tn7asTEZWgLnUwgtOSj {
      margin: 100px; } }
