/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .image_cards___-27P-ikWhpY2q1matRRcZFm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 31.66%;
          flex: 0 0 31.66%;
  margin-bottom: 2.5%;
  cursor: pointer;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 > button {
    display: block;
    height: 100%;
    width: 100%; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0.four___-1gzH_pzVtt7WMzuL_IIry9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 23.8%;
            flex: 0 0 23.8%; }
    html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0.four___-1gzH_pzVtt7WMzuL_IIry9:not(:nth-child(4n)) {
      margin-right: 1.5%; }
    html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0.four___-1gzH_pzVtt7WMzuL_IIry9:nth-child(4) {
      margin-right: 0%; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0:hover {
    opacity: 0.7; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 a {
    display: block; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0:not(:nth-child(3n)) {
    margin-right: 2.5%; }
  html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 p {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem; }
  @media (min-width: 600px) {
    html:not([class*="accessible"]) .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 p {
      font-size: 1.25rem;
      line-height: 1.5rem; } }

html:not([class*="accessible"]) .image_cards__item__image___-3EyooXjp5GXY36e6L3ZkbU {
  height: 30vw;
  max-height: 200px;
  position: relative; }
  html:not([class*="accessible"]) .image_cards__item__image___-3EyooXjp5GXY36e6L3ZkbU:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3); }

html[class*="accessible"] .image_cards___-27P-ikWhpY2q1matRRcZFm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

html[class*="accessible"] .image_cards__item___-DE6ezKcF-QoCy2h5aKxD0 {
  margin-right: 30px; }
