/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.checkout___-35-uS4ksEWjs_IED0pMygm {
  display: block; }
  @media (min-width: 1026px) {
    .checkout___-35-uS4ksEWjs_IED0pMygm {
      margin-top: -45px; } }
  .checkout___-35-uS4ksEWjs_IED0pMygm p {
    font-family: "Akkurat", sans-serif;
    font-weight: 300;
    font-size: 1.0625rem;
    line-height: 1.875rem; }

.checkout__container___-GXnSHwO9g3ZWBBJKLckQy {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 480px) {
    .checkout__container___-GXnSHwO9g3ZWBBJKLckQy {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .checkout__container___-GXnSHwO9g3ZWBBJKLckQy {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .checkout__container___-GXnSHwO9g3ZWBBJKLckQy {
      padding-left: 120px;
      padding-right: 120px; } }
  @media (min-width: 1300px) {
    .checkout__container___-GXnSHwO9g3ZWBBJKLckQy {
      padding-left: 70px;
      padding-right: 70px; } }

.checkout__no_booking_details___-OeFm5wvEnRUnK1Kw7WN2X {
  min-height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.checkout__title___-1IGK735YUOsoTV6N7PjlkM h1 {
  text-align: left;
  font-size: 3rem;
  line-height: 3.375rem;
  margin: 10px 0px 40px; }

.checkout__details___-3IsAu2WA7CVpgkiJVAlnMt {
  background: #f2f2f0;
  margin: 50px -30px 0px;
  padding: 30px; }
  @media (min-width: 768px) {
    .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt {
      margin: 0px -50px;
      padding: 50px; } }
  @media (min-width: 1026px) {
    .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt {
      position: relative;
      padding: 30px 45px;
      border: 1px solid #e2e2e2;
      margin: 0px; } }
  .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt h2 {
    font-size: 1.75rem;
    line-height: 1.75rem; }
  .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt h3 {
    margin: 30px 0px; }
  .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt h3,
  .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt label {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #7f7f7f;
    font-size: 1rem;
    line-height: 1rem; }
  .checkout__details___-3IsAu2WA7CVpgkiJVAlnMt h4 {
    font-family: "Akkurat", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: -15px; }

.checkout__details__breakdown___-1vwK1lwi89OixOzYZhKf2s {
  margin-top: 15px;
  text-align: right; }
  .checkout__details__breakdown___-1vwK1lwi89OixOzYZhKf2s span {
    text-decoration: underline; }

.checkout__details__total___-EvmDELILhEku1XqvAeshW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .checkout__details__total___-EvmDELILhEku1XqvAeshW p {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.625rem; }
    .checkout__details__total___-EvmDELILhEku1XqvAeshW p:first-child {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }

.checkout__details__total__inner___-64-_eaSUWC-Pzjtyzu767 {
  text-align: right; }
  .checkout__details__total__inner___-64-_eaSUWC-Pzjtyzu767 span:not([class*="cta"]) {
    margin-top: 10px;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out; }
    .checkout__details__total__inner___-64-_eaSUWC-Pzjtyzu767 span:not([class*="cta"]):hover {
      opacity: 0.7; }

.checkout__details__fees___-2sCEf_K8nF9l8gLYzAA5eN {
  border-bottom: 1px solid #ffffff;
  padding: 15px 0px;
  margin: 15px 0px; }
  .checkout__details__fees___-2sCEf_K8nF9l8gLYzAA5eN:not(:last-child) {
    border-top: 1px solid #ffffff; }

.checkout__details__fees__row___-1vHBOMAHWXeJTL2_exDDOa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .checkout__details__fees__row___-1vHBOMAHWXeJTL2_exDDOa p:first-child {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.checkout__details__packages__row___-34ugWkdkQA8NK6dkT8SHpv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px; }
  .checkout__details__packages__row___-34ugWkdkQA8NK6dkT8SHpv p:first-of-type {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .checkout__details__packages__row___-34ugWkdkQA8NK6dkT8SHpv > div:first-of-type {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    cursor: pointer;
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out; }
    .checkout__details__packages__row___-34ugWkdkQA8NK6dkT8SHpv > div:first-of-type:hover {
      opacity: 0.7; }
    .checkout__details__packages__row___-34ugWkdkQA8NK6dkT8SHpv > div:first-of-type svg path {
      fill: #f6891f; }

.checkout__details__dates___-UkaAyCiLOAFWrmzMbUvM8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .checkout__details__dates___-UkaAyCiLOAFWrmzMbUvM8 > div:first-child {
    margin-right: 10px; }

.checkout__details__guests___-YnCjwswyEGuK3tE4Ltj1q {
  border-top: 1px solid #ffffff;
  padding: 15px 0px 0px; }

.checkout__columns___-3yHMVKE0SaaEqSOc31k6XL {
  display: block; }
  @media (min-width: 1026px) {
    .checkout__columns___-3yHMVKE0SaaEqSOc31k6XL {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 0px 0px; } }

.checkout__columns__left___-1PZCVq3-1mhUGqI_hklOX {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
  margin-bottom: 30px; }
  @media (min-width: 1026px) {
    .checkout__columns__left___-1PZCVq3-1mhUGqI_hklOX {
      margin-bottom: 0px;
      margin-right: 8.5%; } }

@media (min-width: 1026px) {
  .checkout__columns__right___-3cgpNFvd5np6u1dFkYSmpP {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 40%;
            flex: 1 1 40%;
    margin: 0px;
    padding: 0px;
    background: transparent; } }

.checkout__tabs___-ilsmEMcO1Enj_0RTmX6B {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px; }
  @media (min-width: 1026px) {
    .checkout__tabs___-ilsmEMcO1Enj_0RTmX6B {
      padding: 0px; } }

.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl:not(:last-child) {
  position: relative;
  padding-right: 13px;
  margin-right: 10px; }
  .checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl:not(:last-child):after {
    content: ">";
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }

.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl.active___-2_yX2mrYaH0MQdAk3_Zu25 button,
.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl.active___-2_yX2mrYaH0MQdAk3_Zu25 a,
.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl.active___-2_yX2mrYaH0MQdAk3_Zu25 span {
  color: #f6891f; }

.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl button,
.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl a,
.checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl span {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 0.8125rem; }

@media (min-width: 600px) {
  .checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl:not(:last-child) {
    padding-right: 20px;
    margin-right: 30px; }
  .checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl button,
  .checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl a,
  .checkout__tabs__item___-3hGSlCv63imz4zZW80Aigl span {
    font-size: 1rem;
    line-height: 1rem; } }

.checkout__step___-3hpAyAhnrCwBNpVH1GxXug h1 {
  text-align: left; }

.checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI {
  text-align: right;
  margin-bottom: 50px; }
  .checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI input[type="checkbox"] {
    visibility: hidden; }
  .checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: start;
    margin-top: 2px;
    margin-left: 1.875rem; }
    .checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI label a {
      text-decoration: underline; }
  .checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI .checkbox___-2us9ZbKufYUnXj_AdIRbAo {
    -webkit-transition: background-color 150ms ease-in-out;
    transition: background-color 150ms ease-in-out;
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #000000;
    display: block;
    margin-top: 6.5px;
    cursor: pointer; }
    .checkout__cta_wrapper___-3gxyzxRn74z6Og75TlrVZI .checkbox___-2us9ZbKufYUnXj_AdIRbAo.active___-2_yX2mrYaH0MQdAk3_Zu25::before {
      content: "X";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      color: #f6891f;
      font-weight: bold; }

.checkbox-btn-box___-1Ab9gmNZHgpLVoDjiz70qB {
  position: relative; }
  .checkbox-btn-box___-1Ab9gmNZHgpLVoDjiz70qB button {
    cursor: default; }
  .checkbox-btn-box___-1Ab9gmNZHgpLVoDjiz70qB .active___-2_yX2mrYaH0MQdAk3_Zu25 button {
    cursor: pointer; }
  .checkbox-btn-box___-1Ab9gmNZHgpLVoDjiz70qB .checkbox-text___-1R6MGeLKvxnjU8K_uZ-ygu {
    font-size: 0.67rem;
    font-weight: bold;
    position: absolute;
    top: 22px;
    right: 0px; }

.checkout__cta___-2neXQj7kzulEHcfDKwNHs9 {
  margin-top: 20px; }
  @media (min-width: 1026px) {
    .checkout__cta___-2neXQj7kzulEHcfDKwNHs9 {
      margin-top: 50px; } }

.checkout__step_one_cta___-nPnTsEyKmyQe7-Gfe-UUO {
  display: none; }
  @media (min-width: 1026px) {
    .checkout__step_one_cta___-nPnTsEyKmyQe7-Gfe-UUO {
      display: block; } }

.checkout__confirmation_text___-1FBv_wWI6sR2VlMQDTlBN_ {
  margin-bottom: 50px; }

.checkout__room_details___-J-xCDZ3hXDi4FY7JxdfA5 {
  margin-bottom: 30px; }

.checkout__room_details__image___-2tRTKsUtzBepze8B1p0YTw {
  height: 400px; }

.checkout__room_details__checkin_checkout___-2DXzV23_jgshYF99X7BuVg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0px 10px; }
  .checkout__room_details__checkin_checkout___-2DXzV23_jgshYF99X7BuVg > div:first-of-type {
    margin-right: 30px; }
  .checkout__room_details__checkin_checkout___-2DXzV23_jgshYF99X7BuVg span {
    margin: 0px 10px; }

.checkout__room_details__bedding___-2HpOXk7e8dogRqmB3haJ1I {
  margin: 20px 0px; }
  .checkout__room_details__bedding___-2HpOXk7e8dogRqmB3haJ1I span {
    margin: 0px 15px;
    height: 20px;
    width: 1px;
    background: #7f7f7f;
    display: inline-block;
    position: relative;
    top: 5px; }

.checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN {
  word-break: break-word; }
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN ul {
    padding-left: 20px; }
    .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN ul li {
      list-style: disc; }
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN ol {
    padding-left: 20px; }
    .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN ol li {
      list-style: decimal; }
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN li {
    font-weight: 300; }
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h1,
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h2,
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h3 {
    text-transform: uppercase;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.4375rem; }
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h4,
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h5,
  .checkout__room_details__content___-1MRdh8s1c_7hV1GbOphyEN h6 {
    text-transform: uppercase;
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.1875rem; }

@media (min-width: 1026px) {
  .checkout__details_widget___-1od1ew6av9pS7dp3fRgwok {
    position: -webkit-sticky;
    position: sticky;
    top: 140px; } }

.checkout__previous_booking_details___-31Z_zvGDMsgVJ-ZEmwl2E5 {
  min-height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center; }

.checkout__previous_booking_details__ctas___-3V0WGnPZlp-eebbcYUb11k {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .checkout__previous_booking_details__ctas___-3V0WGnPZlp-eebbcYUb11k > button:first-of-type {
    margin-right: 20px; }

html[class*="accessible"] .checkout__room_details__image___-2tRTKsUtzBepze8B1p0YTw {
  display: none; }
