/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .image_grid___-lHgqPSXFF2tzqMKwUywow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0px -20px; }
  @media (min-width: 480px) {
    html:not([class*="accessible"]) .image_grid___-lHgqPSXFF2tzqMKwUywow {
      margin: 0px -30px; } }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .image_grid___-lHgqPSXFF2tzqMKwUywow {
      margin: 0px; } }

html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5 {
  height: 40vw;
  max-height: 530px;
  margin-bottom: 2.5%; }
  html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(1) {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 27.5%;
            flex: 0 0 27.5%; }
    html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(1):only-of-type {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
  html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(2) {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 70%;
            flex: 1 1 70%; }
  html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(3), html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(4) {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 48.75%;
            flex: 0 0 48.75%; }
  html:not([class*="accessible"]) .image_grid__image___-3HnDk7X-Hq7HDARthIBQk5:nth-of-type(2n) {
    margin-left: 2.5%; }
