/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
html:not([class*="accessible"]) .hero__container___-3lXLHb4_Dx-lihGnqWgoMP {
  position: relative; }
  html:not([class*="accessible"]) .hero__container___-3lXLHb4_Dx-lihGnqWgoMP .hero__content_container___-3LiIHyEC6c8sAilgdLypjQ {
    text-align: center;
    padding: 20px; }
    @media (768px) {
      html:not([class*="accessible"]) .hero__container___-3lXLHb4_Dx-lihGnqWgoMP .hero__content_container___-3LiIHyEC6c8sAilgdLypjQ {
        width: 100%; } }

html:not([class*="accessible"]) .hero__content_container___-3LiIHyEC6c8sAilgdLypjQ {
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 5%;
  right: 5%;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  padding: 0;
  max-width: 1400px;
  margin: 0px auto; }
  @media (min-width: 768px) {
    html:not([class*="accessible"]) .hero__content_container___-3LiIHyEC6c8sAilgdLypjQ {
      left: 10%;
      right: 10%; } }
  @media (min-width: 1300px) {
    html:not([class*="accessible"]) .hero__content_container___-3LiIHyEC6c8sAilgdLypjQ {
      left: 20%;
      right: 20%; } }

html:not([class*="accessible"]) .hero__title___-3-xKFtU02Ad2Sp49F7v-h0 {
  max-width: 700px;
  margin: 0px auto;
  white-space: pre-line;
  color: #ffffff;
  margin-bottom: 0; }
