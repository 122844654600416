@charset "UTF-8";
/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.quote___-2yZv7NwpGDidHgQym5Q3nf {
  margin: 50px auto; }
  @media (min-width: 768px) {
    .quote___-2yZv7NwpGDidHgQym5Q3nf {
      max-width: 1200px;
      margin: 80px auto; } }
  .quote___-2yZv7NwpGDidHgQym5Q3nf p.quote__text___-Z35r783Y8leYpAN5MK4OS {
    font-weight: normal; }
  .quote___-2yZv7NwpGDidHgQym5Q3nf.left___-3mDlDxT4slDcxUech-RaBq .quote__text___-Z35r783Y8leYpAN5MK4OS,
  .quote___-2yZv7NwpGDidHgQym5Q3nf.left___-3mDlDxT4slDcxUech-RaBq .quote__author___-2jgipXggSS4IU_9cl5_ALO {
    text-align: left; }

p.quote__text___-Z35r783Y8leYpAN5MK4OS {
  text-align: center;
  position: relative;
  font-family: "Baskerville", serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0 20px; }
  p.quote__text___-Z35r783Y8leYpAN5MK4OS:before {
    content: "“"; }
  p.quote__text___-Z35r783Y8leYpAN5MK4OS:after {
    content: "”"; }
  @media (min-width: 768px) {
    p.quote__text___-Z35r783Y8leYpAN5MK4OS {
      font-size: 2.375rem;
      line-height: 2.8125rem; } }

.quote__author___-2jgipXggSS4IU_9cl5_ALO {
  text-align: center;
  margin-top: 20px; }
  .quote__author___-2jgipXggSS4IU_9cl5_ALO p {
    display: inline-block;
    font-family: "Baskerville", serif;
    font-style: italic;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    position: relative;
    font-weight: normal; }
    .quote__author___-2jgipXggSS4IU_9cl5_ALO p:before {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      left: 0px;
      top: 50%;
      -webkit-transform: translate(-125%, -50%);
              transform: translate(-125%, -50%); }
