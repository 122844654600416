/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.offers___-3T39H9EBL34oGhOevf91iT {
  display: block; }

.offers__container___-1583L8bbAv5fOpLM2HVEER {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 30px; }
  @media (min-width: 480px) {
    .offers__container___-1583L8bbAv5fOpLM2HVEER {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .offers__container___-1583L8bbAv5fOpLM2HVEER {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .offers__container___-1583L8bbAv5fOpLM2HVEER {
      padding-left: 120px;
      padding-right: 120px; } }

.offers__list___-Q_ppLtXT-WjEYaG931S15 {
  display: block;
  margin: 0px 0px 50px; }
  @media (min-width: 768px) {
    .offers__list___-Q_ppLtXT-WjEYaG931S15 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }

.offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 30px; }
  @media (min-width: 600px) {
    .offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 48%;
              flex: 0 0 48%;
      margin-right: 2.5%;
      margin-bottom: 2.5%; }
      .offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj:nth-of-type(2n) {
        margin-right: 0%; } }
  @media (min-width: 1026px) {
    .offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 31.66669%;
              flex: 0 0 31.66669%;
      margin-bottom: 2.5%; }
      .offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj:nth-of-type(2n) {
        margin-right: 2.5%; }
      .offers__list__item___-3NiSDu4eRKqGMHGrQyJ7uj:nth-of-type(3n) {
        margin-right: 0%; } }

.offers__options___-2lZQnABR-iSY925C4cYa1L {
  margin: 50px 0px 10px; }
  @media (min-width: 768px) {
    .offers__options___-2lZQnABR-iSY925C4cYa1L {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      margin: 100px 0px 10px; } }

.offers__categories___-2HdQALX_XPKnvbvX6z7hRR {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.offers__categories__item___-mju6kGNmhzTnMlQ2HAMU- {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  padding: 10px 18px;
  background: #f2f2f0;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  white-space: pre;
  margin: 0px 10px 20px 0px;
  text-align: center;
  position: relative;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer; }
  .offers__categories__item___-mju6kGNmhzTnMlQ2HAMU-:after {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    content: "";
    opacity: 0;
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid #181818; }
  .offers__categories__item___-mju6kGNmhzTnMlQ2HAMU-:hover {
    background: #181818;
    color: #ffffff; }
    .offers__categories__item___-mju6kGNmhzTnMlQ2HAMU-:hover:after {
      opacity: 1; }

.offers__filters___-2NKqp5AoceNyvulorrNUBg {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.offers__dates___-2fB3g4xW7qXeVxlG9xMn-S {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 10px;
  margin-bottom: 20px; }

.offers__date_picker___-2jiaSREfENOOSTzsY6VxQ0 {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  z-index: 1; }
  .offers__date_picker___-2jiaSREfENOOSTzsY6VxQ0 div[class*="DayPicker"] {
    left: auto;
    right: 0px;
    min-width: 0px; }
  .offers__date_picker___-2jiaSREfENOOSTzsY6VxQ0 div[class*="DayPicker-Caption"] {
    left: auto;
    right: 0px;
    min-width: 0px;
    text-align: center; }
  .offers__date_picker___-2jiaSREfENOOSTzsY6VxQ0 input {
    text-align: center;
    padding: 0px; }

.offers__calendar___-3-gLKZjXVqhYtU5MVB6aVS {
  height: 25px;
  width: 25px;
  margin-right: 15px; }
  .offers__calendar___-3-gLKZjXVqhYtU5MVB6aVS svg {
    width: 100%;
    height: auto; }

.offers__dates_separator___-1rT0Fr8_dcFlC647F13ErS {
  color: #7f7f7f;
  font-size: 0.875rem;
  line-height: 0.875rem; }
